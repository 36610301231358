import { ChangeEvent, FC, useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import looksStyle from "./looks.module.scss";
import { AppDispatch } from "../../../Redux/store";
import { useAsyncActions } from "../../../Hooks/useActions";
import { typedSelector } from "../../../Hooks/useTypedSelector";
import whitePlus from "../../../Assets/Vectors/white_plus.svg";
import { LookDataI } from "../../../Types";
import { change_extra_modal_state, set_all_looks } from "../../../Redux/Actions";
import { choose_hard_coded_look } from "../../../Common/Config";
import { set_inspiration_image_base_64 } from "../../../Redux/Actions/CommonActions/assetsAction";
import { ServiceDataControl } from "../../../Services";
// import { useAppContext } from "../../../Common/Provider/appProvider";

const Looks: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  // const { isRequestProcessing, setIsRequestProcessing } = useAppContext();

  const { get_all_looks, change_wall_or_tile_new, upload_look_new } =
    useAsyncActions();

  const looks = typedSelector((state) => state.common.looks);
  const result = typedSelector((state) => state.common.result);
  const config = typedSelector((state) => state.config);
  const common = typedSelector((state) => state.common);

  useLayoutEffect(() => {
    const app_state = JSON.parse(String(sessionStorage.getItem("common")));
    if (!looks.length && !app_state?.looks.length) {
      // get_all_looks(setIsRequestProcessing);
      get_all_looks();
    }
    if (!looks.length && app_state?.looks.length) {
      dispatch(set_all_looks(app_state.looks));
    }
  }, [looks]);

  const halfway_index = Math.ceil(looks.length / 2);
  const first_column_images = looks.slice(0, halfway_index);
  const second_column_looks = looks.slice(halfway_index);

  return (
    <div className={looksStyle.grid_container}>
      <div className={looksStyle.column_container}>
        {/*<div className={looksStyle.grid_item}>*/}
        {/*  <label*/}
        {/*    htmlFor="upload_look"*/}
        {/*    // htmlFor={cookie.email ? "upload_look" : ""}*/}
        {/*    className={looksStyle.upload}*/}
        {/*  >*/}
        {/*    <div*/}
        {/*      className={looksStyle.plus_img}*/}
        {/*      onClick={(e) => {*/}
        {/*        e.stopPropagation();*/}
        {/*        // dispatch(change_extra_modal_state("Email"));*/}
        {/*        // !cookie.email && dispatch(change_extra_modal_state("Email"));*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      <img src={whitePlus} alt="Add your look" />*/}
        {/*      <div className={looksStyle.text}>*/}
        {/*        <p>Upload My Own</p>*/}
        {/*      </div>*/}
        {/*       {cookie.email && ( */}
        {/*      <input*/}
        {/*        id="upload_look"*/}
        {/*        type="file"*/}
        {/*        style={{ display: "none" }}*/}
        {/*        accept="image/png, image/jpg, image/jpeg"*/}
        {/*        onChange={(e: ChangeEvent<HTMLInputElement>) => {*/}
        {/*          if (e.target.files && e.target.files[0]) {*/}
        {/*            let reader = new FileReader();*/}
        {/*            let baseString;*/}
        {/*            reader.onloadend = function () {*/}
        {/*              baseString = reader.result || "";*/}
        {/*              dispatch(set_inspiration_image_base_64(baseString));*/}
        {/*            };*/}
        {/*            reader.readAsDataURL(e.target.files[0]);*/}
        {/*            ServiceDataControl.remove_session_storage_item("saved_look_url");*/}
        {/*            upload_look_new(*/}
        {/*              e.target.files[0],*/}
        {/*              common,*/}
        {/*              config*/}
        {/*              // setIsRequestProcessing*/}
        {/*            );*/}
        {/*          }*/}
        {/*        }}*/}
        {/*      />*/}
        {/*       )} */}
        {/*    </div>*/}
        {/*  </label>*/}
        {/*</div>*/}
        {first_column_images.map((first_column_image: LookDataI) => (
          <div
            key={`grid_first_column_image_${first_column_image.id}`}
            className={looksStyle.grid_item}
            onClick={(e) => {
              e.stopPropagation();
              // dispatch(change_loading(true));
              choose_hard_coded_look(
                first_column_image,
                dispatch,
                result,
                common,
                config,
                common.look.id,
                // setIsRequestProcessing,
                change_wall_or_tile_new
              );
            }}
          >
            <img
              src={first_column_image.url}
              alt={`look ${first_column_image.id}`}
              className={
                first_column_image.type === "landscape"
                  ? looksStyle.landscape
                  : looksStyle.portrait
              }
            />
          </div>
        ))}
      </div>
      <div className={looksStyle.column_container}>
        {second_column_looks.map((second_column_look: LookDataI) => (
          <div
            key={`grid_second_column_look_${second_column_look.id}`}
            className={looksStyle.grid_item}
            onClick={(e) => {
              e.stopPropagation();
              // dispatch(change_loading(true));
              choose_hard_coded_look(
                second_column_look,
                dispatch,
                result,
                common,
                config,
                common.look.id,
                // setIsRequestProcessing,
                change_wall_or_tile_new
              );
            }}
          >
            <img
              src={second_column_look.url}
              alt={`look ${second_column_look.id}`}
              className={
                second_column_look?.type === "landscape"
                  ? looksStyle.landscape
                  : looksStyle.portrait
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
};
export default Looks;
