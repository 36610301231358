// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".list_container__MhOvW {\n  position: relative;\n}\n.list_container__MhOvW .list_block__NpyVn {\n  display: flex;\n  flex-direction: column;\n  background: #FFF;\n}", "",{"version":3,"sources":["webpack://./src/Component/List/list.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;AAME;EACE,aAAA;EACA,sBAAA;EAEA,gBAAA;AALJ","sourcesContent":[".container {\n  position: relative;\n  // height: 100%;\n  // min-height: 100vh;\n  // overflow-y: scroll;\n  // min-height: 100vh;\n  // color: black;\n  // margin: 0 20px;\n  .block {\n    display: flex;\n    flex-direction: column;\n    // min-height: 100vh;\n    background: #FFF;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "list_container__MhOvW",
	"block": "list_block__NpyVn"
};
export default ___CSS_LOADER_EXPORT___;
