import { NavigateFunction } from "react-router";

export class ConfigControl {
  // protected password = process.env.REACT_APP_PASS as string;
  protected password = "readysetgo";

  check_password(userPassword: string | undefined): boolean {
    if (userPassword === this.password) {
      localStorage.setItem("token", "authorized");
      return true;
    }
    return false;
  }

  // check_authenticate(navigate: NavigateFunction): void {
  //   if (localStorage.getItem("token")) {
  //     return navigate("/home");
  //   }
  // }
}

// export {}
