import { ChangeEvent, FC } from "react";
import captureSpaceStyle from "./captureSpace.module.scss";
import { useAsyncActions } from "../../../Hooks/useActions";
import {change_extra_modal_state, is_clicked, set_360_page_config, set_random} from "../../../Redux/Actions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../Redux/store";
import { typedSelector } from "../../../Hooks/useTypedSelector";
import { set_still_photo_base_64 } from "../../../Redux/Actions/CommonActions/assetsAction";
import View360 from "../View360/view360";
// import { useAppContext } from "../../../Common/Provider/appProvider";

const CaptureSpace: FC = () => {
  const { upload_still_photo, change_wall_or_tile_new } = useAsyncActions();
  const dispatch = useDispatch<AppDispatch>();
  const common = typedSelector((state) => state.common);
  const config = typedSelector((state) => state.config);
  // const [cookie] = useCookies(["email"]);
  // const { isRequestProcessing, setIsRequestProcessing } = useAppContext();

  return (
    <div
      className={captureSpaceStyle.container}
      // className={`${captureSpaceStyle.container} ${`overlay ${
      //   isRequestProcessing ? { pointerEvents: "none" } : ""
      // }`}`}
    >
      <div className={captureSpaceStyle.button_box}>
        <div
          className={captureSpaceStyle.button}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div
            className={captureSpaceStyle.box}
            // onClick={() => {
            //   // !cookie.email &&
            //   dispatch(change_extra_modal_state("Email Still"));
            // }}
          >
            <label
              htmlFor="upload_still_photo"
              // htmlFor={cookie.email ? "upload_still_photo" : ""}
              className={captureSpaceStyle.upload}
            >
              {/* {cookie.email && ( */}
              <input
                id="upload_still_photo"
                type="file"
                style={{ display: "none" }}
                accept="image/png, image/jpg, image/jpeg"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  if (e.target.files && e.target.files[0]) {
                    let reader = new FileReader();
                    let baseString;
                    reader.onloadend = function () {
                      baseString = reader.result || "";
                      dispatch(set_random(false));
                      dispatch(set_still_photo_base_64(baseString));
                    };
                    reader.readAsDataURL(e.target.files[0]);
                    upload_still_photo(
                      e.target.files[0],
                      change_wall_or_tile_new,
                      common,
                      config
                      // setIsRequestProcessing
                    );
                  }
                }}
              />
              {/* )} */}
            </label>
          </div>
        </div>
        <div
          className={captureSpaceStyle.liner}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div
            className={captureSpaceStyle.box1}
            onClick={() => {
              dispatch(set_360_page_config(true))
              dispatch(change_extra_modal_state(""));
            }}
          />
        </div>
      </div>
      <p
        onClick={(e) => {
          e.stopPropagation();
          dispatch(is_clicked(false));
          dispatch(change_extra_modal_state(""));
        }}
      >
        Back
      </p>
    </div>
  );
};

export default CaptureSpace;
