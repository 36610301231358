import { FC, useEffect, useState } from "react";
import lightbulb from "../../../Assets/Icons/lightbulb.svg";
import { vectors } from "../../../Assets/Vectors/vectors";
import tutorial1 from "../../../Assets/Tutorial/SimpleTut1.png";
import tutorial2a from "../../../Assets/Tutorial/SimpleTut2.png";
import tutorial2b from "../../../Assets/Tutorial/SimpleTut3.png";
import tutorial3 from "../../../Assets/Tutorial/SimpleTut4.png";
import tutorial4a from "../../../Assets/Tutorial/SimpleTut5.png";
import tutorial4b from "../../../Assets/Tutorial/SimpleTut6.png";
import tutorialStyle from "./tutorial.module.scss";
import { change_modal } from "../../../Redux/Actions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../Redux/store";

const Tutorial: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [imgState, setImgState] = useState<number>(0);
  // const [height, setHeight] = useState<number>(window.innerHeight);

  // useEffect(() => {

  //   window.addEventListener("resize", () => {
  //     setHeight(window.innerHeight);
  //   });
  //   return () => {
  //     window.removeEventListener("resize", () => {
  //       setHeight(window.innerHeight);
  //     });
  //   };
  // }, [height]);

  useEffect(() => {
    if (openModal) {
      document.body.classList.add("scroll-lightbox-open");
    } else {
      document.body.classList.remove("scroll-lightbox-open");
    }
    return () => {
      document.body.classList.remove("scroll-lightbox-open");
    };
  }, [openModal]);

  return (
    <>
      <>
        <div className={tutorialStyle.info_block}>
          <div
            className={tutorialStyle.tutorial_box}
            onClick={() => setOpenModal(true)}
          >
            <img src={lightbulb} alt="lightbulb" />
            Tutorial
          </div>
          <div
            className={tutorialStyle.tip_box}
            onClick={() => dispatch(change_modal("Tips"))}
          >
            <p>
              <b>
                PRO TIP: <u>Request a sample </u>
              </b>
              to
            </p>
            <p>ensure accurate color/floor selection</p>
            <p> before purchasing as on-screen </p>
            <p>representation may vary.</p>
          </div>
        </div>
      </>
      {openModal && (
        <div className={tutorialStyle.component}>
          <div className={tutorialStyle.box}>
            <img
              src={
                imgState === 0
                  ? tutorial1
                  : imgState === 1
                  ? tutorial2a
                  : imgState === 2
                  ? tutorial2b
                  : imgState === 3
                  ? tutorial3
                  : imgState === 4
                  ? tutorial4a
                  : tutorial4b
              }
              alt="tutorial_1"
            />
            <div className={tutorialStyle.header_box}>
              <div className={tutorialStyle.header_text}>
                <h1></h1>
                <p className={tutorialStyle.steps}>
                  {imgState === 0
                    ? "Step 1/4"
                    : imgState === 1 || imgState === 2
                    ? "Step 2/4"
                    : imgState === 3
                    ? "Step 3/4"
                    : "Step 4/4"}
                </p>
                <p
                  onClick={() => {
                    setOpenModal(false);
                    setImgState(0);
                  }}
                >
                  {vectors.close_tutorial}
                </p>
              </div>
              <p className={tutorialStyle.second_text}>
                {imgState === 0
                  ? "Click the “Your Space” button."
                  : imgState === 1 || imgState === 2
                  ? "Try new products in your space."
                  : imgState === 3
                  ? "Get product samples."
                  : "Show off your new look!"}
              </p>
            </div>
            <div className={tutorialStyle.gradientOverlay}></div>
            <div
              className={tutorialStyle.button}
              // style={{top: height }}
              onClick={() => {
                setImgState(imgState + 1);
                imgState === 5 && setOpenModal(false);
                imgState === 5 && setImgState(0);
              }}
            >
              {imgState === 5 ? "Got it!" : "Next"}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Tutorial;
