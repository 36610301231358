import React, {FC, useEffect} from "react";
import {Toaster} from "react-hot-toast";
import {Navigate, NavigateFunction, Route, Routes, useNavigate} from "react-router";
import SharedPage from "./Component/SharedPage/sharedPage";
import QR from "./Component/Qr/qr";
import List from "./Component/List/list";
import Loading from "./Component/Modals/Loading/loading";
import {routes} from "./Common/Config";
import {ServiceDataControl} from "./Services";
import Auth from "./Component/Auth/auth";

const App: FC = () => {
    //TODO chjnjel
    const navigate: NavigateFunction = useNavigate();

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        event.preventDefault();
        ServiceDataControl.change_page_settings(true);
        event.returnValue = "";
    };

    useEffect(() => {
        window.addEventListener("beforeunload", handleBeforeUnload);
        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        const handlePopstate = () => {
            if (
                window.confirm(
                    "Are you sure you want to leave this page and lose your progress?"
                )
            ) {
            } else {
                console.log("close");
            }
        };

        window.addEventListener("popstate", handlePopstate);

        return () => {
            window.removeEventListener("popstate", handlePopstate);
        };
    });

    useEffect(() => {
        window.sessionStorage.setItem("isReloaded", JSON.stringify(true));

        const handleResize = () => {
            // window.innerWidth <= 768;
        };

        handleResize(); // Call it initially to set the correct value

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);


    return (
        <>
            <Routes>
                <Route
                    path="/"
                    element={
                      !localStorage.getItem("token") ? (
                          <Auth/>
                      ) : window.innerWidth <= 768 ? (
                          <List/>
                      ) : (
                          <QR/>
                      )
                  }
                />
                <Route
                    path={routes.home}
                    element={window.innerWidth <= 768 ? <List/> : <QR/>}
                />
                <Route path={routes.shared_third_page} element={<SharedPage/>}/>
                <Route path="*" element={<Navigate to="/" replace/>}/>
            </Routes>

            <Loading/>
            <Toaster
                toastOptions={{duration: 3000}}
                position="top-center"
                reverseOrder={false}
            />
            {/*<ScreenOrientation />*/}
        </>
    );
};

export default App;
