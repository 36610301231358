import { useEffect, useState } from "react";
import { typedSelector } from "./useTypedSelector";


const useDeviceHeight = (): number => {
  const is_full_screen_enabled = typedSelector(state => state.config.is_full_screen_enabled);

  const [height, setHeight] = useState<number>(window.innerHeight);
  useEffect(() => {
    setHeight(window.innerHeight + 15);
  }, [is_full_screen_enabled]);
  useEffect(() => {
    window.addEventListener("resize", () => {
      setHeight(window.innerHeight + 15);
    });
    return () => {
      window.removeEventListener("resize", () => {
        setHeight(window.innerHeight + 15);
      });
    };
  }, [window.innerHeight]);

  return height;

};

export default useDeviceHeight;