import React, { FC, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { FooterI } from "../../Types";
import footerStyle from "./footer.module.scss";
import { AppDispatch } from "../../Redux/store";
import logo from "../../Assets/Images/logo.svg";
import logo_white from "../../Assets/Images/logo_white.svg";
import { ServiceDataControl } from "../../Services";
import { typedSelector } from "../../Hooks/useTypedSelector";
import {
  change_extra_modal_state,
  change_modal,
  set_common_reducer_state,
  set_config_reducer_state,
} from "../../Redux/Actions";
import {
  common_reducer_initial_state,
  config_reducer_initial_state,
} from "../../Common/Config/reducersConfig";
import { useAsyncActions } from "../../Hooks/useActions";
import { v4 as uuidv4 } from "uuid";
import View360 from "../Modals/View360/view360";
import { NavigateFunction, useNavigate } from "react-router";



const Footer: FC<FooterI> = (props) => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [isMobile, setIsMobile] = useState(true);
  const { get_user_ip } = useAsyncActions();
  const common = typedSelector((state) => state.common);
  const { result_checker_state, upload_look_checker_state } = common;
  const config = typedSelector((state) => state.config);
  const id = uuidv4();

  useEffect(() => {
    get_user_ip();
  }, []);


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      navigate("/");
      // Adjust the threshold as per your requirements
    };

    handleResize(); // Call it initially to set the correct value

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (
      result_checker_state.called_id.ID ||
      upload_look_checker_state.called_id.ID
    ) {
      ServiceDataControl.save_configs(config, common);
    }
  }, [
    result_checker_state.called_id.ID,
    upload_look_checker_state.called_id.ID,
  ]);

  useEffect(() => {
    ServiceDataControl.check_page(dispatch);
    if (!JSON.parse(String(sessionStorage.getItem("SessionID")))) {
      sessionStorage.setItem("SessionID", JSON.stringify(id));
    }
  }, []);

  useEffect(() => {
    if (common.look.url) {
      ServiceDataControl.save_configs(config, common);
    }
  }, [config, common]);

  const memorized_360_page = useMemo(() => {
    return <View360 />;
  }, []);

  return (
    <div className={footerStyle.footer}>
      <div
        className={footerStyle.footer_box}
        onClick={() => {
          dispatch(set_common_reducer_state(common_reducer_initial_state));
          dispatch(set_config_reducer_state(config_reducer_initial_state));
          dispatch(change_extra_modal_state());
          sessionStorage.setItem(
            "common",
            JSON.stringify(common_reducer_initial_state)
          );
          sessionStorage.setItem(
            "config",
            JSON.stringify(config_reducer_initial_state)
          );
          dispatch(change_modal("Inspiration"));
        }}
      >
        <p className={props.classname}>Powered by</p>
        <img
          className={props.classname}
          src={isMobile ? logo : logo_white}
          alt="logo"
        />
      </div>
      {memorized_360_page}
    </div>
  );
};

export default Footer;
