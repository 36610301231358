import { TileTypeDataI, WallColorDataI } from "../../Types";

export const wall_blank: WallColorDataI = {
  id: 0,
  name: "blank",
  type: '',
  rgbCode: [0, 0, 0],
  logo: "",
  urlOnVendorPage: "",
  hexCode: "",
  skuNumber: "",
  vendorName: ""
};

export const tile_blank: TileTypeDataI = {
  id: 0,
  code: "",
  name: "",
  type: "",
  floorIcon: "",
  logo: "",
  urlOnVendorPage: "",
  vendorName: ""
};
