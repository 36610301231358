import { FC } from "react";
import tipStyle from "./tips.module.scss";
import { change_modal } from "../../../Redux/Actions";
import { useDispatch } from "react-redux";
// import getIcon from "../../../Assets/Vectors/sample.svg";
import getSample from "../../../Assets/Vectors/get.svg";
import { AppDispatch } from "../../../Redux/store";

const Tips: FC = () => {
  // const dispatch = useDispatch<AppDispatch>();
  return (
    <>
      <div className={tipStyle.component}>
        <div onClick={(e) => e.stopPropagation()} className={tipStyle.box}>
          <div className={tipStyle.content}>
            <div className={tipStyle.text}>
              <p>Getting a Product Sample</p>
              <p>is the best way to confirm</p>
              <p>your selection is perfect.</p>
            </div>
          </div>
        </div>
        <div
          onClick={(e) => e.stopPropagation()}
          className={tipStyle.bottom_box}
        >
          <div className={tipStyle.bottom_content}>
            <div className={tipStyle.bottom_text}>
              <p>Click this tag next to your</p>
              <p> product selection</p>
            </div>
            <div
              className={tipStyle.paint_vendors_color}
              // onClick={() => dispatch(change_modal("paint"))}
            >
              <img src={getSample} alt="get_sample" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tips;
