import React, { FC, useEffect } from "react";
import headerStyle from "./sphereHeader.module.scss";
import plus from "../../../Assets/Vectors/plus.png";
import greenPlus from "../../../Assets/Vectors/greenPlus.svg";
import greyPlus from "../../../Assets/Vectors/greyPlus.svg";
import { typedSelector } from "../../../Hooks/useTypedSelector";
import { change_modal, is_clicked } from "../../../Redux/Actions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../Redux/store";

const SphereHeader: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const clicked = typedSelector((state) => state.config.clicked);
  const look = typedSelector((state) => state.common.look);
  const result = typedSelector((state) => state.common.result);
  const sphere = typedSelector((state) => state.common.sphere);
  const sphere_status_new_sphere = typedSelector((state) => state.config.sphere_statuses.new_sphere);
  const modal_type = typedSelector((state) => state.config.modal_type)
  const { is_random, sphere_statuses } = typedSelector(
    (state) => state.config
  );


  useEffect(() => {
    sphere_status_new_sphere &&
    window.scrollTo({
      top: 0,
      left: 0
    });
  }, [sphere_status_new_sphere, modal_type]);


  const assets = typedSelector((state) => state.assets);
  const old_area_style =
    assets.still_photo_base_64 ||
    result.old_still_photo_url ||
    sphere.thumbnailUrl ||
    result.old_sphere_url;

  return (
    <div
      className={headerStyle.header}
    >
      {!sphere_statuses.new_sphere ? (
        <div className={headerStyle.disable_area} />
      ) : (
        <></>
      )}
      <div
        onClick={() => {
          if (sphere_statuses.new_sphere) {
            dispatch(change_modal("Inspiration"));
          }
        }}
        className={headerStyle.right}
      >
        <div className={headerStyle.inspiration}>
          {!sphere_statuses.new_sphere && (
            <div className={headerStyle.disabled_block} />
          )}

          <p className={headerStyle.insp_text}>Inspiration</p>
          {assets?.inspiration_image_base_64 || look?.url ? (
            <div
              className={headerStyle.first_image}
              style={{
                backgroundImage: `url(${
                  assets.inspiration_image_base_64 || look.url
                })`
              }}
            />
          ) : (
            <>
              <div
                className={headerStyle.second_image}
                style={{
                  backgroundImage: `${
                    !clicked ? `url(${greyPlus})` : `url(${greenPlus})`
                  }`
                }}
              />
              <div className={headerStyle.text}>
                <p>Add Inspiration</p>
              </div>
            </>
          )}
          {look.url && (
            <button
              onClick={() => {
                dispatch(change_modal("Inspiration"));
              }}
              className={headerStyle.box}
            >
              <p>CHANGE</p>
            </button>
          )}
        </div>
      </div>
      <img src={plus} className={headerStyle.plus} alt="Your space" />
      <div
        className={headerStyle.left}
        onClick={() => {
          if (sphere_statuses.new_sphere) {
            dispatch(is_clicked(true));
            dispatch(change_modal("Space"));
          }
        }}
      >
        <div className={headerStyle.space}>
          {!sphere_statuses.new_sphere && (
            <div className={headerStyle.disabled_block} />
          )}

          <p className={headerStyle.space_text}>Your Space</p>
          {!is_random ? (
            <div
              className={headerStyle.second_image}
              style={{ backgroundImage: `url(${old_area_style})` }}
            />
          ) : (
            <>
              <div
                className={headerStyle.second_image}
                style={{
                  backgroundImage: `${
                    !clicked ? `url(${greyPlus})` : `url(${greenPlus})`
                  }`
                }}
              />
              <div className={headerStyle.text}>
                <p> See In My Space</p>
              </div>
            </>
          )}
          {!is_random && (
            <button className={headerStyle.box}>
              <p>CHANGE</p>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default SphereHeader;
