import { PayloadType,SphereDataI } from "../../../Types";


export const SET_ALL_SPHERES: string = "SET ALL SPHERES";
export const SELECT_SPHERE: string = "SELECT SPHERE";
export const CLEAR_SELECTED_SPHERE: string = "CLEAR SELECTED SPHERE";
export const SET_OLD_SPHERE_URL: string = "SET OLD SPHERE URL";
export const SET_NEW_SPHERE_URL: string = "SET NEW SPHERE URL";
export const NEW_SPHERE_STATUSES: string = "NEW SPHERE STATUSES";
export const OLD_SPHERE_STATUSES: string = "OLD SPHERE STATUSES";


// ----------------------SPHERE-----------------------------------------
export const set_new_spheres_status = (payload = false): PayloadType<boolean> => {
  return {
    type: NEW_SPHERE_STATUSES,
    payload
  };
};

export const set_old_spheres_status = (payload = false): PayloadType<boolean> => {
  return {
    type: OLD_SPHERE_STATUSES,
    payload
  };
};

export const set_sphere = (payload: SphereDataI): PayloadType<SphereDataI> => {
  return {
    type: SELECT_SPHERE,
    payload
  };
};
export const set_all_spheres = (payload: SphereDataI[]): PayloadType<SphereDataI[]> => {
  return {
    type: SET_ALL_SPHERES,
    payload
  };
};


export const clear_selected_sphere = (): PayloadType<null> => {
  return { type: CLEAR_SELECTED_SPHERE };
};

export const set_old_sphere_url = (payload: {
  url: string;
  endpoint: string;
}): PayloadType<{
  url: string;
  endpoint: string;
}> => {
  return {
    type: SET_OLD_SPHERE_URL,
    payload
  };
};
export const set_new_sphere_url = (payload: string): PayloadType<string> => {
  return {
    type: SET_NEW_SPHERE_URL,
    payload
  };
};

