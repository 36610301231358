import { LoadingTimeI, PayloadType, RCommonStateI, ResultCheckerI, ResultDataI } from "../../../Types";
import { MarkerPositionI } from "../../../Common/Markers/marker";

export const SET_SESSION_ID: string = "SET SESSION ID";
export const RESULT_CHECKER: string = "RESULT CHECKER";
export const CLEAR_RESULT_CHECKER: string = "CLEAR RESULT CHECKER";
export const UPLOAD_LOOK_CHECKER: string = "UPLOAD CHECKER";
export const CLEAR_UPLOAD_LOOK_CHECKER: string = "CLEAR UPLOAD LOOK CHECKER";

export const SET_COMMON_REDUCER_STATE: string = "SET COMMON REDUCER STATE";
export const SET_RESULT_DATA: string = "SET RESULT DATA";
export const CLEAR_RESULT_DATA: string = "CLEAR RESULT DATA";

export const SET_LOADING_TIME: string = "SET LOADING TIME";

export const CLEAR_LOADING_TIME: string = "CLEAR LOADING TIME";

export const SHARED_PAGE: string = "SHARED PAGE";

export const STILL_PHOTO_LOGO_LOCATIONS: string = "STILL PHOTO LOGO LOCATIONS";


export const set_still_photo_logo_location = (payload: MarkerPositionI): PayloadType<MarkerPositionI> => {
  return {
    type: STILL_PHOTO_LOGO_LOCATIONS,
    payload
  }
}


export const set_loading_time = (
  payload: LoadingTimeI
): PayloadType<LoadingTimeI> => {
  return {
    type: SET_LOADING_TIME,
    payload,
  };
};

export const clear_loading_time = (): { type: string } => {
  return { type: CLEAR_LOADING_TIME };
};

export const shared_page = (payload = false): PayloadType<boolean> => {
  return {
    type: SHARED_PAGE,
    payload,
  };
};

export const set_result_checker = (
  payload: ResultCheckerI
): PayloadType<ResultCheckerI> => {
  return {
    type: RESULT_CHECKER,
    payload,
  };
};

export const clear_result_checker = (): PayloadType<null> => {
  return {
    type: CLEAR_RESULT_CHECKER,
  };
};

export const upload_look_checker = (
  payload: ResultCheckerI
): PayloadType<ResultCheckerI> => {
  return {
    type: UPLOAD_LOOK_CHECKER,
    payload,
  };
};

export const clear_upload_look_checker = (): PayloadType<null> => {
  return {
    type: CLEAR_UPLOAD_LOOK_CHECKER,
  };
};

export const set_result_data = (
  payload: ResultDataI
): PayloadType<ResultDataI> => {
  return {
    type: SET_RESULT_DATA,
    payload,
  };
};

export const clear_result_data = () => {
  return { type: CLEAR_RESULT_DATA };
};

export const set_common_reducer_state = (
  payload: RCommonStateI
): PayloadType<RCommonStateI> => {
  return {
    type: SET_COMMON_REDUCER_STATE,
    payload,
  };
};
