import { LookDataI, PayloadType, AllLookI } from "../../../Types";

export const SET_ALL_LOOKS: string = "SET ALL LOOKS";
export const SELECT_LOOK: string = "SELECT LOOK";
export const CLEAR_SELECTED_LOOK: string = "CLEAR SELECTED LOOK";
export const UPLOADED_LOOK_URL: string = "UPLOADED LOOK URL";

export const uploaded_look_url = (
  payload: LookDataI
): PayloadType<LookDataI> => {
  return {
    type: UPLOADED_LOOK_URL,
    payload,
  };
};

export const set_look = (payload: LookDataI): PayloadType<LookDataI> => {
  return {
    type: SELECT_LOOK,
    payload,
  };
};
export const set_all_looks = (payload: AllLookI): PayloadType<AllLookI> => {
  return {
    type: SET_ALL_LOOKS,
    payload,
  };
};

export const clear_selected_look = (): PayloadType<null> => {
  return {
    type: CLEAR_SELECTED_LOOK,
  };
};
