export class ApiControl {
  protected app_domain = process.env.REACT_APP_DOMAIN as string;
  protected server_domain = process.env.REACT_APP_SERVER_DOMAIN as string;
  protected server_domain_endpoint = process.env
    .REACT_APP_SERVER_DOMAIN_ENDPOINT as string;

  get_wall_color(id: number): string {
    return `${this.server_domain_endpoint}/walls/${id}`;
  }

  get_floor_tile(id: number): string {
    return `${this.server_domain_endpoint}/floors/${id}`;
  }

  get_domain_name(): string {
    return this.app_domain;
  }
  get_all_looks_url(): string {
    return `${this.server_domain_endpoint}/hardcoded-looks`;
  }

  get_user_count(): string {
    return `${this.server_domain_endpoint}/users-count`;
  }

  update_user_count(): string {
    return `${this.server_domain_endpoint}/users`;
  }

  update_email(): string {
    return `${this.server_domain_endpoint}/custom-users`;
  }

  get_upload_still_photo_url(): string {
    return `${this.server_domain_endpoint}/uploadStillPhoto`;
  }

  get_all_spheres_url(): string {
    return `${this.server_domain_endpoint}/getHardcodedSpheres`;
  }

  get_hardcoded_spheres_image_source(): string {
    return `${this.server_domain}/hardcoded_spheres`;
  }

  upload_look_new(): string {
    return `${this.server_domain_endpoint}/v2/uploadLookNew`;
  }

  uploaded_look_url(look: string): string {
    return `${this.server_domain}/look_images/${look}.jpg`;
  }

  read_hardcoded_look_url(look: string): string {
    return `${this.server_domain}/look_images/hardcoded_looks/looks_v2/${look}.jpg`;
  }

  get_upload_look_result(id: string): string {
    return `${this.server_domain_endpoint}/getUploadLookResult/${id}`;
  }

  get_changed_wall_tile_new(): string {
    return `${this.server_domain_endpoint}/v2/applyLookNew`;
  }

  get_apply_look_result(id: string): string {
    return `${this.server_domain_endpoint}/v2/applyLookResult/${id}`;
  }

  read_new_sphere_url(sphere: string): string {
    return `${this.server_domain}/photosphere_results_hardcoded/${sphere}.jpg`;
  }

  read_old_sphere_url(sphere: string): string {
    return `${this.server_domain}/hardcoded_spheres/${sphere}.jpg`;
  }

  get_positions_of_logo(new_sphere_name: string): string {
    return `${this.server_domain_endpoint}/getSphereLabels?sphereName=${new_sphere_name}`;
  }

  read_old_still_photo_url(still_photo: string): string {
    return `${this.server_domain}/still_images/${still_photo}`;
  }

  read_new_still_photo_url(still_photo: string): string {
    return `${this.server_domain}/still_images/${still_photo}.jpg`;
  }

  get_random_walls_tiles_url(
    offset: number,
    limit: number,
    type: string
  ): string {
    return `${this.server_domain_endpoint}/random-${type}s?offset=${offset}&limit=${limit}`;
  }

  get_next_random_walls_tiles_url(link: string): string {
    return `${link}`;
  }

  read_random_tiles_url(code: string, include?: boolean): string {
    return `${
      this.server_domain_endpoint
    }/randomfloors?code=${code}&include=${!!include}`;
  }

  send_customer_form(): string {
    return `${this.server_domain_endpoint}/forms`;
  }

  set_rate_and_review(): string {
    return `${this.server_domain_endpoint}/ratings`;
  }

  text_user_form(): string {
    return `${this.server_domain_endpoint}/contacts`;
  }

  text_me_form(): string {
    return `${this.server_domain_endpoint}/reminder`;
  }

  get_url_on_vendor_page(): string {
    return `${this.server_domain_endpoint}/addClick`;
  }

  get_look_info_url(look_name: string): string {
    return `${this.server_domain_endpoint}/look-info/${look_name}`;
  }

  get_wall_info_url(wall_id: string): string {
    return `${this.server_domain_endpoint}/walls/${wall_id}`;
  }

  get_tile_info_url(tile_id: string): string {
    return `${this.server_domain_endpoint}/floors/${tile_id}`;
  }
}
