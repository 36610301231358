import React, { Dispatch, FC, SetStateAction, useState } from "react";
import QrStyle from "./qrStyle.module.scss";
import logo from "../../Assets/Images/logo.svg";
import imgWeb from "../../Assets/Web/web_image.jpg";
import imgBottom from "../../Assets/Web/web_footer.jpg";
import qrBack from "../../Assets/Web/qr_back.svg";
import qr from "../../Assets/Web/qr.jpg";
import Footer from "../Footer/footer";
import upIcon from "../../Assets/Icons/up.png";

interface QrI {
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const QR: FC = () => {
  const [open, setOpen] = useState(false);

  if (open) {
    return (
      <div
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          zIndex: "100",
          width: "100%",
          background: "white",
          display: "flex",
          justifyContent: "center",
          alignItems: "start",
          // height: "100%",
          height: "100vh",
          overflowY: "auto",
        }}
      >
        <iframe
          scrolling="auto"
          // ref={iframeRef}
          // height={`${iframeHeight}px`}
          width="375px"
          height="100%"
          src="https://studio.dospace.tech/"
          style={{
            width: "375px",
            height: "100%",
            // height: "3500px",
            // position: "absolute",
            border: "none",
            overflow: "hidden",
          }}
          // onLoad={() => adjustIframeHeight()}
          // ref={iframeRef}
          title="app"
        />
        {/* <img
          src={upIcon}
          style={{ position: "initial",  height: "3500px", width: "375px" }}
        /> */}
      </div>
    );
  }

  return (
    <div
      className={QrStyle.qr_wrapper}
      // style={mobile || location.pathname === "/auth"
      //   ? { zIndex: -5 } : { zIndex: 100 }}
    >
      <div className={QrStyle.component}>
        <div className={QrStyle.qr_box}>
          <div className={QrStyle.qr_content}>
            <img src={qrBack} alt="bgd qr" className={QrStyle.qr_bgd} />
            <img src={qr} alt="bgd qr" className={QrStyle.qr} />
          </div>
          <div className={QrStyle.bottom_content}>
            <p className={QrStyle.bottom_text}>Scan to experience on mobile!</p>
          </div>
        </div>
        <div className={QrStyle.container}>
          <div className={QrStyle.header}>
            <img src={logo} alt="logo" />
          </div>
          <div className={QrStyle.main_img_box}>
            <img src={imgWeb} alt="web img" className={QrStyle.main_img} />
          </div>
        </div>
        <div className={QrStyle.bottom_img_box}>
          <img
            src={imgBottom}
            alt="web img bottom"
            className={QrStyle.bottom_img}
          />
          <div className={QrStyle.footer_content}>
            <Footer classname={QrStyle.footer} />
            <p className={QrStyle.text}>
              Copyright DoSpace, Inc. 2023 | (402) 534-1402 | v1.0
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QR;
