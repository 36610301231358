import { AnyAction } from "redux";
import {
  CLEAR_INSPIRATION_IMAGE_BASE_64,
  CLEAR_STILL_PHOTO_BASE_64,
  SET_INSPIRATION_IMAGE_BASE_64,
  SET_STILL_PHOTO_BASE_64,
} from "../Actions/CommonActions/assetsAction";

interface AssetsReducerI {
  still_photo_base_64: string;
  inspiration_image_base_64: string;
}

const initialState: AssetsReducerI = {
  still_photo_base_64: "",
  inspiration_image_base_64: "",
};

export const assetsReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_STILL_PHOTO_BASE_64:
      return { ...state, still_photo_base_64: action.payload };
    case CLEAR_STILL_PHOTO_BASE_64:
      return { ...state, still_photo_base_64: "" };
    case SET_INSPIRATION_IMAGE_BASE_64:
      return { ...state, inspiration_image_base_64: action.payload };
    case CLEAR_INSPIRATION_IMAGE_BASE_64:
      return { ...state, inspiration_image_base_64: "" };
    default:
      return state;
  }
};
