import React, { ChangeEvent, FC, useState } from "react";
import authStyle from "./auth.module.scss";
import { NavigateFunction, useNavigate } from "react-router";
import { PasswordStateI } from "../../Types/types";
import { ServiceConfig } from "../../Services";

const Auth: FC = () => {
  const navigate: NavigateFunction = useNavigate();

  const [passwordState, changePasswordState] = useState<PasswordStateI>({
    password: "",
    errorMessage: "",
  });
  const [passwordType, setPasswordType] = useState("password");

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };


  const login = (userPassword: string) => {
    if (ServiceConfig.check_password(userPassword)) {
      return navigate("/", { replace: true });
    }
    changePasswordState({
      ...passwordState,
      errorMessage: "Check your password",
    });
  };

  return (
    <div className={authStyle.container}>
      <div className={authStyle.box}>
        <div className={authStyle.textWrapper}>
          <p className={authStyle.title}>Guest Area</p>
          <p className={authStyle.text}>Please enter the password below.</p>
        </div>
        <div className={authStyle.wrapper}>
          <div className={authStyle.inputLine}>
            <input
              // type="password"
              placeholder="Password"
              id="password"
              className={authStyle.inputStyle}
              type={passwordType}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  login(passwordState.password);
                }
              }}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                changePasswordState({
                  ...passwordState,
                  password: e.target.value,
                })
              }
            />
            <button className={authStyle.eye} onClick={togglePassword}>
              {passwordType === "password" ? (
                <i className="bi bi-eye-slash"></i>
              ) : (
                <i className="bi bi-eye"></i>
              )}
            </button>
          </div>
          <p>{passwordState.errorMessage}</p>
          <button
            onClick={() => login(passwordState.password)}
            className={authStyle.loginButton}
          >
            Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default Auth;

// export {}
