import { AppDispatch } from "../Redux/store";
import { ServiceApi } from "../Services";
import { LookDataI, SphereDataI } from "../Types";
import {
  set_old_sphere_url,
  set_sphere,
  set_sphere_type,
  set_still_photo_type,
} from "../Redux/Actions";

export class SphereController {
  handler_sphere = (
    sphere: SphereDataI,
    dispatch: AppDispatch,
    look: LookDataI,
    look_uploaded: boolean
    // common: RCommonStateI,
    // config: RConfigStateI
  ): void => {
    // ServiceDataControl.get_default_look(dispatch, look_uploaded, look);
    // ServiceDataControl.remove_last_selected_wall_and_tile();
    // ServiceDataControl.save_configs(
    //     {...config, is_still_photo: true},
    //     {
    //         ...common,
    //         result: {
    //             sphere_endpoint: "",
    //             still_photo_endpoint: "",
    //             old_sphere_url: "",
    //             new_sphere_url: "",
    //             old_still_photo_url: "",
    //             new_still_photo_url: "",
    //         },
    //         result_checker_state: {
    //             called_count: 0,
    //             called_id: {
    //                 ID: 0,
    //                 minutesRemaining: 0,
    //                 secondsRemaining: 0
    //             },
    //         },
    //     }
    // );
    // if (JSON.parse(String(sessionStorage.getItem("default_look")))?.url) {
    //     sessionStorage.setItem(
    //         "common",
    //         JSON.stringify({
    //             ...JSON.parse(String(sessionStorage.getItem("common"))),
    //             look: {
    //                 ...JSON.parse(String(sessionStorage.getItem("default_look"))),
    //             },
    //             result_checker_state: {
    //                 called_count: 0,
    //                 called_id: {
    //                     ID: 0,
    //                     timeRemaining: 0,
    //                 },
    //             },
    //         })
    //     );
    // }
    dispatch(
      set_old_sphere_url({
        url: `${ServiceApi.get_hardcoded_spheres_image_source()}/${
          sphere?.name
        }.jpg`,
        endpoint: sphere?.name,
      })
    );
    dispatch(set_still_photo_type(false));
    // dispatch(change_loading(true));
    dispatch(set_sphere_type());
    dispatch(set_sphere(sphere));
  };
}
