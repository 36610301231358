import { useDispatch } from "react-redux";
import { AppDispatch } from "../../Redux/store";
import { Viewer } from "@photo-sphere-viewer/core";
import {
  change_extra_modal_state,
  change_loading,
  change_modal,
  clear_loading_time,
  is_clicked,
  set_new_spheres_status,
  set_old_spheres_status
} from "../../Redux/Actions";
import { typedSelector } from "../../Hooks/useTypedSelector";
import { FC, useEffect, useMemo, useRef } from "react";
import { SpheresPositionsI } from "../../Types";
import resultStyle from "../Sphere/SphereResult/sphereResult.module.scss";
import { change_marker, handler_hardcoded_sphere_logo_location } from "../../Common/Config";
import { GyroscopePlugin, MarkersPlugin, ReactPhotoSphereViewer, StereoPlugin } from "react-photo-sphere-viewer";
import view360_off from "../../Assets/Vectors/view_360_off.svg";
import view360_on from "../../Assets/Vectors/view_360_on.svg";
import { isMobile } from "react-device-detect";
import { ServiceDataControl, ServicePagesControl } from "../../Services";
import {
  fullscreen_icon,
  green_dots_and_tag_markers_functional,
  showed_sphere_icon,
  use_two_fingers,
  write_sphere_type
} from "./photosphereViewerConfig";
import ViewerLoading from "./MiniLoading/photosphereLoading";
import share_vector from "../../Assets/Vectors/share.svg";
import toast from "react-hot-toast";
import useAutorotate from "../../Hooks/ViewerHooks/useAutorotate";
import useViewerMarkers from "../../Hooks/ViewerHooks/useViewerMarkers";
import useDeviceHeight from "../../Hooks/useDeviceHeight";
import useGyroscope from "../../Hooks/ViewerHooks/useGyroscope";

const PhotoSphereViewer: FC = () => {
  const dispatch = useDispatch<AppDispatch>();


  const logos_positions = typedSelector((state) => state.common.logos_positions);
  const look = typedSelector((state) => state.common.look);
  const result = typedSelector((state) => state.common.result);
  const sphere = typedSelector((state) => state.common.sphere);
  const result_checker_state = typedSelector((state) => state.common.result_checker_state);


  const is_new_sphere = typedSelector((state) => state.config.is_new_sphere);
  const is_still_photo = typedSelector((state) => state.config.is_still_photo);
  const look_uploaded = typedSelector((state) => state.config.look_uploaded);
  const sphere_statuses = typedSelector((state) => state.config.sphere_statuses);
  const material_type = typedSelector((state) => state.config.material_type);
  const is_full_screen_enabled = typedSelector((state) => state.config.is_full_screen_enabled);
  const is_random = typedSelector((state) => state.config.is_random);


  // const [is_fullscreen_enabled, change_fullscreen_state] =
  //   useState<boolean>(false);

  const new_photoSphereRef = useRef<typeof ReactPhotoSphereViewer>(),
    old_photoSphereRef = useRef<typeof ReactPhotoSphereViewer>();
  const spheres_position = useRef<SpheresPositionsI>(
    handler_hardcoded_sphere_logo_location(sphere.endpoint)
  );


  useAutorotate(new_photoSphereRef.current);
  useViewerMarkers(new_photoSphereRef, old_photoSphereRef, is_full_screen_enabled);
  const device_height = useDeviceHeight();
  const { gyro_check_func, is_gyroscope_on } = useGyroscope(new_photoSphereRef, old_photoSphereRef, spheres_position);

  // SET NEW PANORAMA & AUTOROTATE
  useEffect(() => {
    if (!new_photoSphereRef.current) return;
    if (result.new_sphere_url) {
      dispatch(set_new_spheres_status());
      const animate = {
        yaw: logos_positions.rotate_sphere[0],
        pitch: logos_positions.rotate_sphere[1],
        zoom: 0
      };
      new_photoSphereRef.current
        //@ts-ignore
        .setPanorama(result.new_sphere_url)
        ?.then(() => {
          //@ts-ignore
          new_photoSphereRef?.current?.animate(animate);
          //@ts-ignore
          const markers = new_photoSphereRef.current?.getPlugin("markers");
          markers?.updateMarker(
            change_marker(
              "wall",
              look?.wallColor,
              logos_positions,
              material_type
            )
          );
          markers?.updateMarker(
            change_marker(
              "tile",
              look?.floorTile,
              logos_positions,
              material_type
            )
          );
        })
        ?.then(() => {
          const autorotate_div = document.getElementById("autorotate");
          if (autorotate_div) {
            autorotate_div.style.display = "block";
          }
          green_dots_and_tag_markers_functional(dispatch, look, material_type);
          const autorotate_plugin =
            //@ts-ignore
            new_photoSphereRef?.current.getPlugin("autorotate");
          //@ts-ignore
          autorotate_plugin.start();
          autorotate_plugin.config.autorotateSpeed = 0.09; // 0.2093
          autorotate_plugin.config.autorotatePitch = 0.04; // 0.0872
          autorotate_plugin.config.autorotateZoomLvl = 0; // null
          dispatch(set_new_spheres_status(true));
          dispatch(clear_loading_time());
        })
        .catch(() => {
          dispatch(change_loading(false));
          ServiceDataControl.get_last_viewed_style(dispatch);
          ServiceDataControl.get_steps(dispatch);
          dispatch(is_clicked(false));
          dispatch(change_extra_modal_state());
          dispatch(change_modal());
          ServiceDataControl.get_last_viewed_style(dispatch);
          // toast.error("Something went wrong, please try again 333");
          dispatch(clear_loading_time());
        });
    }
  }, [result.new_sphere_url]);

  // SET OLD PANORAMA
  useEffect(() => {
    if (!old_photoSphereRef.current) return;
    if (result.old_sphere_url) {
      dispatch(set_old_spheres_status());
      old_photoSphereRef?.current
        //@ts-ignore
        .setPanorama(result?.old_sphere_url)
        ?.then(() => {
          //@ts-ignore
          // dispatch(change_loading(false));
        })
        ?.then(() => {
          dispatch(set_old_spheres_status(true));
        });
    }
  }, [result?.old_sphere_url]);


  const copied_url: string = ServicePagesControl.share_url_generator(
    look.url,
    is_still_photo ? result.new_still_photo_url : result.new_sphere_url,
    is_still_photo,
    look_uploaded,
    result_checker_state.called_id.ID || 0
  );


  // USE TWO FINGERS
  useEffect(() => {
    if (!new_photoSphereRef.current && !old_photoSphereRef.current) return;
    use_two_fingers();
  }, [new_photoSphereRef.current, old_photoSphereRef.current]);

  const new_sphere = useMemo(() => {
    if (result?.new_sphere_url) {
      return (
        <ReactPhotoSphereViewer
          ref={new_photoSphereRef}
          src={result.new_sphere_url}
          height="100%"
          width="100%"
          container={""}
          navbar={false}
          containerClass={resultStyle.new_sphere}
          defaultYaw={spheres_position.current.yaw}
          defaultPitch={spheres_position.current.pitch}
          defaultZoomLvl={0}
          // keyboard={false}
          // touchmoveTwoFingers={true}
          plugins={[
            [
              GyroscopePlugin,
              {
                touchmove: true,
                absolutePosition: true,
                moveMode: "smooth"
              }
            ],
            StereoPlugin,
            [
              MarkersPlugin,
              {
                markers: [
                  change_marker(
                    "wall",
                    look?.wallColor,
                    logos_positions,
                    material_type
                  ),
                  change_marker(
                    "tile",
                    look?.floorTile,
                    logos_positions,
                    material_type
                  )
                ]
              }
            ]
          ]}
          // keyboardActions={{
          //   f: (viewer: Viewer): void => {
          //     viewer.toggleFullscreen();
          //   }
          // }}
          onZoomChange={({ zoomLevel }) => {
            spheres_position.current = {
              ...spheres_position.current,
              zoom: zoomLevel
            };
          }}
          onPositionChange={(_lat: number, _lng: number, instance: Viewer) => {
            const positions = instance.getPosition();
            spheres_position.current = {
              ...spheres_position.current,
              pitch: positions.pitch,
              yaw: positions.yaw
            };
          }}
          // loadingTxt={""}
          onReady={() => {
            dispatch(set_new_spheres_status(true));
          }}
        />
      );
    }
  }, [
    result?.new_sphere_url,
    new_photoSphereRef,
    // spheres_is_ready,
    is_new_sphere
  ]);

  const old_sphere = useMemo(() => {
    if (result?.old_sphere_url) {
      return (
        <ReactPhotoSphereViewer
          ref={old_photoSphereRef}
          container={""}
          src={result.old_sphere_url}
          height="100%"
          width="100%"
          navbar={false}
          // touchmoveTwoFingers={true}
          plugins={[
            [
              GyroscopePlugin,
              {
                touchmove: true,
                absolutePosition: true,
                moveMode: "smooth"
              }
            ],
            StereoPlugin
          ]}
          containerClass={resultStyle.old_sphere}
          defaultYaw={spheres_position.current.yaw}
          defaultPitch={spheres_position.current.pitch}
          defaultZoomLvl={0}
          // keyboard={false}
          // keyboardActions={{
          //   f: (viewer: Viewer): void => {
          //     viewer.toggleFullscreen();
          //   }
          // }}
          onZoomChange={({ zoomLevel }) => {
            spheres_position.current = {
              ...spheres_position.current,
              zoom: zoomLevel
            };
          }}
          onPositionChange={(_lat: number, _lng: number, instance: Viewer) => {
            const positions = instance.getPosition();
            spheres_position.current = {
              ...spheres_position.current,
              pitch: positions.pitch,
              yaw: positions.yaw
            };
          }}
          onReady={() => {
            dispatch(set_old_spheres_status(true));
          }}
        />
      );
    }
  }, [
    result?.old_sphere_url,
    old_photoSphereRef,
    // spheres_is_ready,
    is_new_sphere
  ]);


  return (
    <div
      style={is_full_screen_enabled ? { height: device_height } : {}}
      className={
        is_full_screen_enabled ? resultStyle.viewer_2 : resultStyle.viewer
      }
    >
      {is_random && write_sphere_type()}
      <div className={resultStyle.back_fon} />
      <div
        style={{ display: !sphere_statuses.new_sphere ? "none" : "block" }}
        className={resultStyle.navbar}
      >
        {/*FULL SCREEN*/}
        {fullscreen_icon(is_full_screen_enabled, dispatch)}

        {/*SHARE*/}
        {is_new_sphere && !is_full_screen_enabled && (
          <img
            className={resultStyle.share_button}
            onClick={() => {
              if (navigator?.share) {
                navigator
                  .share({
                    title: "Check out this look in my space!",
                    url: copied_url
                  })
                  .then(() => console.log("Success"))
                  .catch(() => console.log("Canceled"));
              } else {
                console.warn("Your browser doesn't have native share");
              }
              if (navigator?.clipboard && navigator?.clipboard?.writeText) {
                navigator.clipboard.writeText(copied_url).then(() => {
                  toast("Link copied to clipboard");
                });
              } else {
                console.warn("Please update your browser!!");
              }
            }}
            src={share_vector}
            alt="share"
          />
        )}
        {/*VIEW || NEW / OLD*/}
        {showed_sphere_icon(is_new_sphere, dispatch)}
      </div>

      {/*GYROSCOPE TOGGLE*/}
      {isMobile && (
        <div
          onClick={() => {
            gyro_check_func(is_gyroscope_on);
          }}
          className={
            is_full_screen_enabled
              ? resultStyle.gyroscope_bottom
              : resultStyle.gyroscope_top
          }
        >
          <img src={is_gyroscope_on ? view360_on : view360_off} alt="Gyroscope button" />
        </div>
      )}
      {!sphere_statuses.new_sphere && <ViewerLoading sphere_type="new" />}
      <div
        className={
          is_new_sphere
            ? `${resultStyle.new_sphere} ${resultStyle.active_viewer_new}`
            : resultStyle.new_sphere
        }
      >
        <div id="autorotate" className={resultStyle.autorotate} />
        {new_sphere}
      </div>
      <div
        className={
          !is_new_sphere
            ? `${resultStyle.old_sphere} ${resultStyle.active_viewer_old}`
            : resultStyle.old_sphere
        }
      >
        {/*{!sphere_statuses.old_sphere && <ViewerLoading sphere_type="old" />}*/}
        {old_sphere}
      </div>
    </div>
  );
};

export default PhotoSphereViewer;
