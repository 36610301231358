import {
  PayloadType,
  FloorTileI,
  WallColorI,
  WallColorDataI,
  ItemI,
} from "../../../Types";

export const CHANGE_LOOK_WALL: string = "CHANGE LOOK WALL";
export const CHANGE_LOOK_TILE: string = "CHANGE LOOK TILE";
export const SET_RANDOM_WALLS: string = "SET RANDOM WALLS";
export const SET_RANDOM_TILES: string = "SET RANDOM TILES";
export const SET_COUNT_RANDOM: string = "SET COUNT RANDOM";
export const SET_PAGE_RANDOM: string = "SET PAGE RANDOM";
export const SET_CONTINUE_RANDOM_WALLS: string = "SET CONTINUE RANDOM WALLS";
export const SET_CONTINUE_RANDOM_TILES: string = "SET CONTINUE RANDOM TILES"

export const set_random_walls = (
  payload: WallColorI[] | []
): PayloadType<WallColorI[] | []> => {
  return {
    type: SET_RANDOM_WALLS,
    payload,
  };
};

export const set_random_tiles = (
  payload: FloorTileI[] | []
): PayloadType<FloorTileI[] | []> => {
  return {
    type: SET_RANDOM_TILES,
    payload,
  };
};

export const select_wall = (
  payload: WallColorDataI
): PayloadType<WallColorDataI> => {
  return {
    type: CHANGE_LOOK_WALL,
    payload,
  };
};

export const select_tile = (payload: FloorTileI): PayloadType<FloorTileI> => {
  return {
    type: CHANGE_LOOK_TILE,
    payload,
  };
};

export const set_page_random = (payload: ItemI): PayloadType<ItemI> => {
  return {
    type: SET_PAGE_RANDOM,
    payload,
  };
};

export const set_count_random = (payload: number): PayloadType<number> => {
  return {
    type: SET_COUNT_RANDOM,
    payload,
  };
};

export const get_next_random_walls = (
  payload: string
): PayloadType<string> => {
  return {
    type: SET_CONTINUE_RANDOM_WALLS,
    payload,
  };
};

export const get_next_random_tiles = (
  payload: string
): PayloadType<string> => {
  return {
    type: SET_CONTINUE_RANDOM_TILES,
    payload,
  };
};
