import { FC, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../Redux/store";
import { typedSelector } from "../../Hooks/useTypedSelector";
import Modal from "../Modal/modal";
import Looks from "../../Component/Modals/Looks/looks";
import {change_extra_modal_state, change_loading, change_modal} from "../../Redux/Actions";
import modalStyle from "../Modal/modal.module.scss";
import Spaces from "../../Component/Modals/Spaces/spaces";
import GetBids from "../../Component/Modals/Bids/bids";
import Congratulation from "../../Component/Modals/Congratulation/congratulation";
import Tips from "../../Component/Modals/Tips/tips";
import axios, { CancelTokenSource } from "axios";
import { useAsyncActions } from "../../Hooks/useActions";
import GetPaintAndFloor from "../../Component/Modals/GetPaintAndFloor/getPaintAndFloor";
import GetLink from "../../Component/Modals/GetLink/getLink";
import RateAndReview from "../../Component/Modals/Review/rateAndReview";
// import { useAppContext } from "../Provider/appProvider";

export const ModalsList: FC = () => {
  const { upload_look, change_wall_or_tile_new } = useAsyncActions();
  const dispatch = useDispatch<AppDispatch>();
  const config = typedSelector((state) => state.config);
  const common = typedSelector((state) => state.common);
  const { text_thanks, modal_type, material_type } = config;
  const { upload_look_checker_state } = common;
  // const [cookie] = useCookies(["email"]);
  // const { setIsRequestProcessing } = useAppContext();
  
  const upload_look_checker_source: CancelTokenSource =
    axios.CancelToken.source();

  useEffect(() => {
    if (upload_look_checker_state.called_id.ID && !config.is_reminder) {
      // dispatch(change_loading(true));
      upload_look(
        upload_look_checker_state,
        upload_look_checker_source.token,
        change_wall_or_tile_new,
        common,
        config,
        // setIsRequestProcessing
      );
    }

    return () => {
      upload_look_checker_source.cancel();
    };
  }, [
    upload_look_checker_state.called_count,
    upload_look_checker_state.called_id.ID,
  ]);

  // const memo_email = useMemo(() => {
  //   return (is_still = false) => {
  //     return (
  //       !cookie.email && (
  //         <Modal
  //           arrow_back_button={false}
  //           title_text_h1={""}
  //           title_text_h2={""}
  //           context={<Email is_still={is_still} />}
  //           onClose={() => dispatch(change_modal())}
  //           classname={modalStyle.extra_email}
  //           close={true}
  //         />
  //       )
  //     );
  //   };
  // }, []);


  return (
    <>
      {/* {modal_type === "Email" && memo_email()} */}
      {modal_type === "LinkMe" && (
          <Modal
              arrow_back_button={false}
              title_text_h1={""}
              title_text_h2={""}
              context={<GetLink />}
              classname={modalStyle.extra_link}
              onClose={() => dispatch(change_modal())}
              close={true}
          />
      )}
      {modal_type === "Inspiration" && (
        <Modal
          arrow_back_button={false}
          title_text_h1={"Pick Any Design"}
          title_text_h2={"that inspires you"}
          context={<Looks />}
          onClose={() => dispatch(change_modal())}
          classname={modalStyle.inspiration}
          close={!!common.look.url}
        />
      )}
      {modal_type === "Space" && (
        <Modal
          arrow_back_button={false}
          title_text_h1={"Pick a Space"}
          title_text_h2={"to visualize your design"}
          context={<Spaces />}
          classname={modalStyle.space}
          onClose={() => dispatch(change_modal())}
          close={false}
        />
      )}
      {(modal_type === "paint" || modal_type === "floor") && (
        <Modal
          arrow_back_button={false}
          title_text_h1={""}
          title_text_h2={""}
          context={<GetPaintAndFloor props={material_type} />}
          onClose={() => dispatch(change_modal())}
          classname={modalStyle.paint_and_floor}
          close={true}
        />
      )}
      {modal_type === "Congratulation" && (
        <Modal
          arrow_back_button={false}
          title_text_h1={""}
          title_text_h2={""}
          context={<Congratulation />}
          classname={modalStyle.congratulation}
          onClose={() => dispatch(change_modal())}
          close={true}
        />
      )}
        {modal_type === "RateAndReview" && (
        <Modal
          arrow_back_button={false}
          title_text_h1={""}
          title_text_h2={""}
          context={<RateAndReview />}
          classname={modalStyle.review}
          onClose={() => dispatch(change_modal())}
          close={true}
        />
      )}
      {modal_type === "Bids" && (
        <Modal
          arrow_back_button={false}
          title_text_h1={""}
          title_text_h2={""}
          context={<GetBids />}
          classname={modalStyle.bid}
          onClose={() => dispatch(change_modal("close"))}
          close={true}
        />
      )}
      {modal_type === "Thanks" && (
        <Modal
          arrow_back_button={false}
          title_text_h1={"Thanks!"}
          title_text_h2={text_thanks}
          context={""}
          onClose={() => dispatch(change_modal("close"))}
          classname={`${modalStyle.thanks}`}
          close={true}
        />
      )}
      {modal_type === "Tips" && (
        <Modal
          arrow_back_button={false}
          title_text_h1={""}
          title_text_h2={""}
          context={<Tips />}
          classname={modalStyle.tips}
          onClose={() => dispatch(change_modal("close"))}
          close={true}
        />
      )}
    </>
  );
};
