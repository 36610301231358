import React, { FC, useEffect, useState } from "react";
import vendorsInfoStyle from "./vendorsInfo.module.scss";
// import getIcon from "../../Assets/Vectors/sample.svg";
import getSample from "../../Assets/Vectors/get.svg";
import { change_modal } from "../../Redux/Actions";
import { useAsyncActions } from "../../Hooks/useActions";
import { typedSelector } from "../../Hooks/useTypedSelector";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../Redux/store";
// import { useAppContext } from "../../Common/Provider/appProvider";

const VendorsInfo: FC = () => {
  const { redirect_url } = useAsyncActions();
  const dispatch = useDispatch<AppDispatch>();
  const { material_type, modal_type } = typedSelector((state) => state.config);
  const { look } = typedSelector((state) => state.common);
  const { wallColor, floorTile } = look;
  const color =
    material_type === "wall" ? wallColor.hexCode : floorTile.floorIcon;
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    <div
      className={vendorsInfoStyle.vendors_info}
      // className={`${vendorsInfoStyle.vendors_info} ${`overlay ${
      //   isRequestProcessing ? { pointerEvents: "none" } : ""
      // }`}`}
      style={
        modal_type !== "paint" || modal_type !== "floor"
          ? { background: "#f0f0f0" }
          : { background: "#f5f6f5" }
      }
    >
      {material_type === "wall" &&
        (wallColor.id !== 0 ? (
          <>
            <div
              className={vendorsInfoStyle.floor_vendors_logo}
              onClick={() => {
                redirect_url({
                  material: "wallColor",
                  id: wallColor.id,
                  url: wallColor.urlOnVendorPage,
                  // setIsRequestProcessing: setIsRequestProcessing,
                });
                // isMobile
                //   ?
                window.open(wallColor.urlOnVendorPage, "_self");
                //   : window.open(wallColor.urlOnVendorPage, "_blank");
              }}
            >
              <img
                alt="wall logo"
                src={wallColor.logo}
                className={vendorsInfoStyle.floor_vendors_logo_info}
              />
              <div className={vendorsInfoStyle.floor_vendors_color_info}>
                <div className={vendorsInfoStyle.floor_vendors_color_name}>
                  <b>
                    
                    <u>{wallColor.name.substring(0, 15)}</u>
                  </b>
                </div>
                <div className={vendorsInfoStyle.floor_vendors_color_hex}>
                  {wallColor.skuNumber}
                </div>
              </div>
            </div>

            <div
              style={{ background: color }}
              className={vendorsInfoStyle.floor_vendors_left}
            >
              <div className={vendorsInfoStyle.floor_vendors_tag}>
                <div className={vendorsInfoStyle.floor_vendors_color}>
                  {modal_type !== "paint" && (
                    // <>
                    //   <img
                    //     src={getIcon}
                    //     alt="get_icon"
                    //     className={vendorsInfoStyle.without}
                    //   />
                    <div
                      className={vendorsInfoStyle.get_sample}
                      onClick={() => dispatch(change_modal("paint"))}
                    >
                      {/* <p>Get</p>
                        <p>Sample</p> */}
                      <img src={getSample} alt="get_sample" />
                    </div>
                    // </>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className={vendorsInfoStyle.unselected}>No Wall Selected</div>
        ))}

      {material_type === "tile" &&
        (floorTile.id !== 0 ? (
          <>
            <div
              className={vendorsInfoStyle.floor_vendors_logo}
              style={
                modal_type !== "floor"
                  ? { background: "#f0f0f0" }
                  : { background: "#f5f6f5" }
              }
              onClick={() => {
                redirect_url({
                  material: "floorTile",
                  id: floorTile.id,
                  url: floorTile.urlOnVendorPage,
                  // setIsRequestProcessing: setIsRequestProcessing,
                });
                // isMobile
                //   ?
                window.open(floorTile.urlOnVendorPage, "_self");
                //   : window.open(floorTile.urlOnVendorPage, "_blank");
              }}
            >
              <img
                alt="floor logo"
                src={floorTile.logo}
                className={vendorsInfoStyle.floor_vendors_logo_info}
              />
              <div className={vendorsInfoStyle.floor_vendors_color_info}>
                <div className={vendorsInfoStyle.floor_vendors_color_name}>
                  <b>
                    <u>{floorTile.name.substring(0, 15)}</u>
                  </b>
                </div>
                <div className={vendorsInfoStyle.floor_vendors_color_hex}>
                  {floorTile.code}
                </div>
              </div>
            </div>
            <div
              className={vendorsInfoStyle.floor_vendors_left}
              style={{ backgroundImage: `url(${color})` }}
            >
              <div className={vendorsInfoStyle.floor_vendors_tag}>
                <div className={vendorsInfoStyle.floor_vendors_color}>
                  {modal_type !== "paint" && (
                    // <>
                    //   <img
                    //     src={getIcon}
                    //     alt="get_icon"
                    //     className={vendorsInfoStyle.without}
                    //   />

                    <div
                      className={vendorsInfoStyle.get_sample}
                      onClick={() => dispatch(change_modal("paint"))}
                    >
                      {/* <p>Get</p>
                        <p>Sample</p> */}
                      <img src={getSample} alt="get_sample" />
                    </div>
                    // </>
                  )}
                </div>
              </div>

              {/* <img
                src={color}
                alt="get_icon"
                className={vendorsInfoStyle.floor_vendors_color}
              />
              <img
                src={getIcon}
                alt="get_icon"
                className={
                  modal_type !== "floor"
                    ? `${vendorsInfoStyle.without}`
                    : `${vendorsInfoStyle.floor_get_icon}`
                } */}
              {/* /> */}
              {/* {modal_type !== "floor" && (
                <div onClick={() => dispatch(change_modal("floor"))}>
                  <p>Get</p>
                  <p>Sample</p>
                </div>
              )} */}
              {/* <div
                className={vendorsInfoStyle.floor_vendors_flag}
                style={
                  modal_type !== "floor"
                    ? { background: "#f0f0f0" }
                    : { background: "#f5f6f5" }
                }
              ></div> */}
            </div>
          </>
        ) : (
          <div className={vendorsInfoStyle.unselected}>No Floor Selected</div>
        ))}
    </div>
  );
};

export default VendorsInfo;
