import { useEffect, useState } from "react";
import { GyroscopePlugin } from "react-photo-sphere-viewer";
import { typedSelector } from "../useTypedSelector";


const useGyroscope = (new_photoSphereRef: any, old_photoSphereRef: any, spheres_position:any) => {
  const [is_gyroscope_on, change_gyroscope_state] = useState<boolean>(false);
  const config = typedSelector((state) => state.config);
  const {
    is_new_sphere,
  } = config;


  const gyro_check_func = (is_gyroscope_on: boolean) => {
    if (!new_photoSphereRef.current || !old_photoSphereRef.current) return;
    const old_panorama_gyroscope_plugin =
      //@ts-ignore
      old_photoSphereRef.current?.getPlugin(GyroscopePlugin);
    const new_panorama_gyroscope_plugin =
      //@ts-ignore
      new_photoSphereRef.current?.getPlugin(GyroscopePlugin);
    if (is_new_sphere) {
      new_panorama_gyroscope_plugin?.toggle();
      change_gyroscope_state(!is_gyroscope_on);
    } else {
      old_panorama_gyroscope_plugin?.toggle();
      change_gyroscope_state(!is_gyroscope_on);
    }
  };


  useEffect(() => {
    if (!new_photoSphereRef.current || !old_photoSphereRef.current) return;
    const old_panorama_gyroscope_plugin =
      //@ts-ignore
      old_photoSphereRef.current?.getPlugin(GyroscopePlugin);
    const new_panorama_gyroscope_plugin =
      //@ts-ignore
      new_photoSphereRef.current?.getPlugin(GyroscopePlugin);
    if (is_new_sphere) {
      const old_panorama_gyroscope_state = old_panorama_gyroscope_plugin?.state;
      if (old_panorama_gyroscope_state?.enabled) {
        new_panorama_gyroscope_plugin?.start();
        change_gyroscope_state(true);
      } else {
        new_panorama_gyroscope_plugin?.stop();
        change_gyroscope_state(false);
      }
    } else {
      const new_panorama_gyroscope_state = new_panorama_gyroscope_plugin?.state;
      if (new_panorama_gyroscope_state?.enabled) {
        old_panorama_gyroscope_plugin?.start();
        change_gyroscope_state(true);
      } else {
        old_panorama_gyroscope_plugin?.stop();
        change_gyroscope_state(false);
      }
    }
    if (
      !old_panorama_gyroscope_plugin?.state?.enabled &&
      !new_panorama_gyroscope_plugin?.state?.enabled
    ) {
      //@ts-ignore
      new_photoSphereRef.current?.animate({
        ...spheres_position.current
      });
      //@ts-ignore
      old_photoSphereRef.current?.animate({
        ...spheres_position.current
      });
    }
  }, [is_new_sphere, new_photoSphereRef, old_photoSphereRef]);


  return {gyro_check_func,is_gyroscope_on}

}


export default useGyroscope;