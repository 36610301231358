import { FC, useMemo } from "react";
import { typedSelector } from "../../Hooks/useTypedSelector";
import Modal from "../Modal/modal";
import Email from "../../Component/Modals/Email/email";
import modalStyle from "../Modal/modal.module.scss";
import CaptureSpace from "../../Component/Modals/Spaces/captureSpace";
import GetLink from "../../Component/Modals/GetLink/getLink";
import GetPaintAndFloor from "../../Component/Modals/GetPaintAndFloor/getPaintAndFloor";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../Redux/store";
import { change_extra_modal_state } from "../../Redux/Actions";
// import { useCookies } from "react-cookie";
import View360 from "../../Component/Modals/View360/view360";

const ExtraModal: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { extra_modal_type, text_thanks } = typedSelector((state) => state.config);
  // const [cookie] = useCookies(["email"]);

  // const memorized_email_modal = useMemo(() => {
  //   return (is_still = false) => {
  //     return (
  //       !cookie.email && (
  //         <Modal
  //           arrow_back_button={false}
  //           title_text_h1={""}
  //           title_text_h2={""}
  //           context={<Email is_still={is_still} />}
  //           onClose={() => dispatch(change_extra_modal_state())}
  //           classname={
  //             is_still ? modalStyle.extra_email_still : modalStyle.email_inspiration
  //           }
  //           close={true}
  //         />
  //       )
  //     );
  //   };
  // }, []);


  return (
    <>
     {extra_modal_type === "Thanks" && (
        <Modal
          arrow_back_button={false}
          title_text_h1={"Thanks!"}
          title_text_h2={text_thanks}
          context={""}
          onClose={() => dispatch(change_extra_modal_state())}
          classname={`${modalStyle.thanks}`}
          close={true}
        />
      )}
      {/* {extra_modal_type === "Email" && memorized_email_modal(false)} */}
      {/*{extra_modal_type === "View" && (*/}
      {/*  <Modal*/}
      {/*    arrow_back_button={false}*/}
      {/*    title_text_h1={""}*/}
      {/*    title_text_h2={""}*/}
      {/*    context={<View360 />}*/}
      {/*    onClose={() => dispatch(change_extra_modal_state())}*/}
      {/*    classname={modalStyle.extra_view}*/}
      {/*    close={false}*/}
      {/*  />*/}
      {/*)}*/}
      {/* {extra_modal_type === "Email Still" && memorized_email_modal(true)} */}
      {extra_modal_type === "Capture" && (
        <Modal
          arrow_back_button={false}
          title_text_h1={"Capture Your Space"}
          title_text_h2={""}
          context={<CaptureSpace />}
          classname={modalStyle.extra_still}
          onClose={() => dispatch(change_extra_modal_state())}
          close={false}
        />
      )}
      {extra_modal_type === "LinkMe" && (
        <Modal
          arrow_back_button={false}
          title_text_h1={""}
          title_text_h2={""}
          context={<GetLink />}
          classname={modalStyle.extra_link}
          onClose={() => dispatch(change_extra_modal_state())}
          close={true}
        />
      )}
    </>
  );
};

export default ExtraModal;
