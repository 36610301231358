import { ReactElement } from "react";

interface VectorsI {
  arrow_back: ReactElement;
  close_modal: ReactElement;
  close: ReactElement;
  close_tutorial: ReactElement;
}

export const vectors: VectorsI = {
  arrow_back: (
    <svg
      width="14"
      height="26"
      viewBox="0 0 14 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 25L1 13L13 1"
        stroke="#59D07F"
        strokeWidth="0.75"
        strokeMiterlimit="10"
      />
    </svg>
  ),
  close_modal: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M14 1.47914L12.5209 0L7 5.52086L1.47915 0L0 1.47914L5.52085 7L0 12.5209L1.47915 14L7 8.47914L12.5209 14L14 12.5209L8.47915 7L14 1.47914Z"
        fill="#4DB671"
      />
    </svg>
  ),
  close: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12.5995 11.9928L23.8771 0.713017C23.9555 0.633956 23.9994 0.527207 23.9994 0.415965C23.9994 0.304722 23.9555 0.197973 23.8771 0.118912C23.7968 0.0424407 23.6901 -0.000152022 23.5792 4.07727e-07C23.4683 0.000152838 23.3617 0.043039 23.2816 0.119731L12.0057 11.3987L0.728073 0.118912C0.647749 0.0429412 0.541343 0.000602889 0.430733 0.000602889C0.320123 0.000602889 0.213717 0.0429412 0.133394 0.118912C0.0551111 0.197834 0.0111919 0.304447 0.0111919 0.415555C0.0111919 0.526664 0.0551111 0.633277 0.133394 0.712199L11.4118 11.9928L0.134213 23.2726C0.0928296 23.311 0.0596217 23.3574 0.0365737 23.4089C0.0135257 23.4604 0.00111083 23.5161 7.11897e-05 23.5725C-0.000968452 23.6289 0.00938837 23.6849 0.0305228 23.7373C0.0516572 23.7896 0.0831351 23.8371 0.123075 23.877C0.163016 23.9169 0.210599 23.9484 0.26298 23.9695C0.315362 23.9906 0.371466 24.001 0.427941 23.9999C0.484416 23.9989 0.540101 23.9865 0.591669 23.9635C0.643237 23.9404 0.689629 23.9073 0.728073 23.8659L12.0057 12.5869L23.2824 23.8667C23.3214 23.9057 23.3677 23.9367 23.4187 23.9578C23.4697 23.979 23.5243 23.9899 23.5795 23.9899C23.6347 23.9899 23.6893 23.9791 23.7403 23.9581C23.7913 23.937 23.8377 23.9061 23.8767 23.8671C23.9158 23.8282 23.9468 23.7819 23.9679 23.731C23.9891 23.6801 24 23.6255 24 23.5704C24 23.5153 23.9892 23.4607 23.9681 23.4097C23.947 23.3588 23.9161 23.3125 23.8771 23.2734L12.5995 11.9928Z"
        fill="white"
        fillOpacity="0.5"
      />
    </svg>
  ),
  close_tutorial: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M14 1.47914L12.5209 0L7 5.52086L1.47915 0L0 1.47914L5.52085 7L0 12.5209L1.47915 14L7 8.47914L12.5209 14L14 12.5209L8.47915 7L14 1.47914Z"
        fill="#787878"
      />
    </svg>
  ),
};
