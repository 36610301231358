import {ChangeEvent, FC, useState} from "react";
import getLinkStyle from "./getLink.module.scss";
import {toast} from "react-hot-toast";
import {isValidate} from "../../../Common/Config";
import useDebounce from "../../../Hooks/useDebounce";
import {useAsyncActions} from "../../../Hooks/useActions";
import {Mobile_Info} from "../../../Types";
import {typedSelector} from "../../../Hooks/useTypedSelector";
import {
    change_extra_modal_state,
    change_modal,
    set_common_reducer_state,
    set_config_reducer_state
} from "../../../Redux/Actions";
import {common_reducer_initial_state, config_reducer_initial_state} from "../../../Common/Config/reducersConfig";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../Redux/store";
// import { useAppContext } from "../../../Common/Provider/appProvider";

const GetLink: FC = () => {
    const dispatch = useDispatch<AppDispatch>()
    const {text_me_form} = useAsyncActions();
    const id = typedSelector(
        (state) => state.common.result_checker_state.called_id.ID
    );
    const {look, sphere} = typedSelector((state) => state.common);
    const {is_still_photo, modal_type, extra_modal_type} = typedSelector(
        (state) => state.config
    );
    // const { isRequestProcessing, setIsRequestProcessing } = useAppContext();
    const [mobile_input, set_mobile_input] = useState<Mobile_Info>({
        processId: 0,
        phone: "",
        lookUrl: "",
        sphereUrl: "",
        type: "",
        error: "",
    });
    const process_type = !is_still_photo ? "customLook" : "stillPhoto";

    const check_form = () => {
        if (isValidate("number", mobile_input.phone)) {
            text_me_form({
                phone: debounced_phone,
                processId: id,
                lookUrl: look.url,
                sphereUrl: sphere.sphereUrl,
                type: process_type,
                error: "",
            })
            dispatch(set_common_reducer_state(common_reducer_initial_state))
            dispatch(set_config_reducer_state(config_reducer_initial_state))
            dispatch(change_extra_modal_state());
            sessionStorage.setItem(
                "common",
                JSON.stringify(common_reducer_initial_state)
            );
            sessionStorage.setItem(
                "config",
                JSON.stringify(config_reducer_initial_state)
            );
            dispatch(change_modal("Inspiration"));
        } else {
            toast.error(mobile_input.error || "Check your phone number");
            set_mobile_input({
                ...mobile_input,
                error: "Check your phone number",
            });
        }
    };

    const debounced_phone = useDebounce<string>(mobile_input.phone, 500);

    return (
        <div
            className={getLinkStyle.component}
            // className={`${getLinkStyle.component} ${`overlay ${
            //   isRequestProcessing ? { pointerEvents: "none" } : ""
            // }`}`}
        >
            <div onClick={(e) => e.stopPropagation()} className={getLinkStyle.box}>
                <div className={getLinkStyle.content}>
                    <p>Get A Link</p>
                    <p>As Soon As It’s Ready!</p>
                    <input
                        id="phone"
                        type="number"
                        placeholder="*Mobile Number"
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            set_mobile_input({
                                ...mobile_input,
                                phone: e.target.value,
                            });
                        }}
                    />
                    <div className={getLinkStyle.button} onClick={check_form}>
                        Text Me
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GetLink;
