import React, { FC, useEffect } from "react";
import modalStyle from "./modal.module.scss";
import { vectors } from "../../Assets/Vectors/vectors";
import { ModalI } from "../../Types";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../Redux/store";
import { typedSelector } from "../../Hooks/useTypedSelector";
import { is_clicked } from "../../Redux/Actions";

const Modal: FC<ModalI> = (props) => {
  const {
    context,
    background,
    classname,
    onClose,
    arrow_back_button,
    title_text_h1,
    title_text_h2,
    close,
  } = props;

  const dispatch = useDispatch<AppDispatch>();
  const { modal_type, extra_modal_type } = typedSelector(
    (state) => state.config
  );
  const { new_sphere_url, new_still_photo_url } = typedSelector(
    (state) => state.common.result
  );

  useEffect(() => {
    if (modal_type !== "") {
      document.body.classList.add("scroll-lightbox-open");
    } else {
      document.body.classList.remove("scroll-lightbox-open");
    }
    return () => {
      document.body.classList.remove("scroll-lightbox-open");
    };
  }, [modal_type, extra_modal_type]);

  if (modal_type === "") {
    return null;
  }

  return (
    <div
      className={`${
        extra_modal_type
          ? modalStyle.container_extra_modal
          : modalStyle.container_modal
      } ${classname}`}
      // id="test_modal"
      onClick={() => {
        (new_sphere_url || new_still_photo_url) && onClose();
        dispatch(is_clicked(false));
      }}
    >
      <div
        className={`${
          (modal_type === "Inspiration" && extra_modal_type !== "LinkMe") ||
          (modal_type === "Space" && extra_modal_type === "") ||
          (modal_type === "Space" && extra_modal_type === "Capture") ||
          (modal_type === "Thanks" && extra_modal_type === "")
            ? modalStyle.content
            : modalStyle.modals_content
        }`}
        onClick={() => {
          if (modal_type === "Thanks") {
            onClose();
          }
        }}
      >
        <div className={modalStyle.header_modal}>
          <div
            className={`${modalStyle.vector_modal} ${
              (modal_type === "Thanks" && modalStyle.box,
              modal_type !== "Inspiration" && modalStyle.mg)
            }`}
          >
            <div className={modalStyle.arrow_modal}>
              {arrow_back_button ? vectors.arrow_back : ""}
            </div>
            <div className={modalStyle.close_modal} onClick={onClose}>
              {close ? vectors.close_modal : ""}
            </div>
          </div>
          <div
            className={`${modalStyle.title_modal} ${
              modal_type === "Thanks" && modalStyle.box
            }`}
          >
            <h1 className={modalStyle.first_text}>{title_text_h1}</h1>
            <h2 className={modalStyle.second_text}>{title_text_h2}</h2>
          </div>
        </div>
        <div className={modalStyle.modals}>{context}</div>
      </div>
    </div>
  );
};

export default Modal;
