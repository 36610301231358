import FormData from "form-data";
import toast from "react-hot-toast";
import { AppDispatch } from "../../store";
import { ServiceApi } from "../../../Services";
import {
  change_extra_modal_state,
  change_loading,
  change_material_type,
  change_modal,
  change_reminder,
  set_config_reducer_state,
  set_more_text,
  text_thanks,
} from "../CommonActions/configAction";
import axios, { AxiosError, CancelToken, GenericFormData } from "axios";
import {
  Customer_Info,
  EmailStateI,
  Mobile_InfoI,
  ObjectForSendI,
  Rating_Info,
  VendorI,
} from "../../../Types";
import { CookieSetOptions } from "universal-cookie";
import {
  clear_result_checker,
  set_common_reducer_state,
  upload_look_checker,
} from "../CommonActions/commonAction";
import {
  common_reducer_initial_state,
  config_reducer_initial_state,
} from "../../../Common/Config/reducersConfig";
import { clear_selected_look } from "../CommonActions/lookAction";

const expires_on = new Date();
expires_on.setDate(new Date().getDate() + 300);

export const send_customer_form =
  (data: Customer_Info) => async (dispatch: AppDispatch) => {
    try {
      // data.setIsRequestProcessing(true);
      const formData = new FormData();
      formData.append("name", data.name);
      formData.append("phone", data.phone);
      formData.append(data.props, data.id);
      const response = await fetchingDataWithAxiosMiddleware(
        "POST",
        ServiceApi.send_customer_form(),
        formData
      );
      if (response.status) {
        dispatch(change_extra_modal_state());
        dispatch(change_modal("Thanks"));
        dispatch(
          text_thanks("We’ve sent access to your coupons and samples via text!")
        );
        // data.setIsRequestProcessing(false);
      } else {
        return false;
      }
    } catch (e) {
      const error = e as AxiosError;
      dispatch(change_loading(false));
      toast.error(error.message || "Something wrong in form, please try again");
    }
  };

export const rate_and_review =
  (
    data: Rating_Info,
    setCookie: (
      name: "rateReview",
      value: any,
      options?: CookieSetOptions | undefined
    ) => any
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      const formData = new FormData();
      formData.append("numberOfStars", data.numberOfStars);
      formData.append("comment", data.comment);
      const response = await fetchingDataWithAxiosMiddleware(
        "POST",
        ServiceApi.set_rate_and_review(),
        formData
      );
      if (response.status) {
        setCookie("rateReview", data.numberOfStars || data.comment, {
          expires: expires_on,
        });
        dispatch(change_extra_modal_state());
        dispatch(change_modal("Thanks"));
        dispatch(
          text_thanks("Thanks! We appreciate your feedback!")
        );
        // data.setIsRequestProcessing(false);
      } else {
        return false;
      }
    } catch (e) {
      const error = e as AxiosError;
      dispatch(change_loading(false));
      toast.error(error.message || "Something wrong in form, please try again");
    }
  };

export const redirect_url =
  (data: VendorI) => async (dispatch: AppDispatch) => {
    try {
      // data.setIsRequestProcessing(true);
      const formData = new FormData();
      formData.append("material", data.material);
      formData.append("id", data.id);
      const response = await fetchingDataWithAxiosMiddleware(
        "POST",
        ServiceApi.get_url_on_vendor_page(),
        formData
      );

      if (response?.status) {
        // data.setIsRequestProcessing(false);
        return true;
      }
    } catch (e) {
      const error = e as AxiosError;
      dispatch(change_loading(false));
      toast.error(error.message || "Redirect not complited, please try again");
    }
  };

export const fetchingDataWithAxiosMiddleware = async (
  method: string,
  url: string,
  formData?: GenericFormData,
  cancelToken?: CancelToken
) => {
  const handlerObjectForSend: ObjectForSendI = {
    method,
    url,
    headers: {
      "Content-Type": "multipart/form-data",
      SessionID: `${sessionStorage.getItem("SessionID")}`,
      IP: `${sessionStorage.getItem("ip")}`,
    },
  };
  if (cancelToken) {
    handlerObjectForSend.cancelToken = cancelToken;
  }
  if (method !== "GET") {
    handlerObjectForSend.data = formData;
  }
  return axios(handlerObjectForSend);
};

export const get_user_ip =
  () =>
  async (dispatch: AppDispatch): Promise<string> => {
    try {
      const response = await fetch("https://ipapi.co/json/");
      const data = await response.json();

      sessionStorage.setItem("ip", data.ip);
      return `${data.ip}`;
    } catch (e) {
      const error = e as AxiosError;
      toast.error(error.message || "Something wrong in form, please try again");
      return "";
    }
  };

export const text_user_form =
  (
    data: EmailStateI,
    setCookie: (
      name: "email",
      value: any,
      options?: CookieSetOptions | undefined
    ) => any,
    setIsRequestProcessing?: (value: boolean) => void | null
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      // setIsRequestProcessing(true);
      const formData = new FormData();
      formData.append("email", data.email);
      formData.append("message ", data.message);
      const response = await fetchingDataWithAxiosMiddleware(
        "POST",
        ServiceApi.text_user_form(),
        formData
      );
      if (response.status) {
        setCookie("email", data.email, { expires: expires_on });
        // data.setIsRequestProcessing(false);
        dispatch(change_extra_modal_state());
        dispatch(change_material_type("wall"));
        dispatch(set_more_text(""));
        dispatch(change_modal("Thanks"));
        dispatch(text_thanks("We appreciate your feedback and suggestions!"));
      } else {
        return false;
      }
    } catch (e) {
      const error = e as AxiosError;
      dispatch(change_loading(false));
      toast.error(error.message || "Something wrong in form, please try again");
    } finally {
      // setIsRequestProcessing(false);
    }
  };

export const text_me_form =
  (data: Mobile_InfoI) => async (dispatch: AppDispatch) => {
    try {
      // data.setIsRequestProcessing(true);
      const formData = new FormData();
      formData.append("phone", data.phone);
      formData.append("lookUrl", data.lookUrl);
      formData.append("sphereUrl", data.sphereUrl);
      formData.append("type", data.type);
      formData.append("processId", data.processId);
      const response = await fetchingDataWithAxiosMiddleware(
        "POST",
        ServiceApi.text_me_form(),
        formData
      );

      if (response.status === 200) {
        // data.setIsRequestProcessing(false);
        dispatch(change_reminder(true));
        dispatch(clear_result_checker());
        dispatch(
          upload_look_checker({
            called_count: 0,
            called_id: {
              ID: 0,
              minutesRemaining: 0,
              secondsRemaining: 0,
            },
          })
        );
        // dispatch(clear_upload_look_checker());
        dispatch(clear_selected_look());
        // dispatch(clear_result_data());
        // dispatch(clear_selected_sphere())
        dispatch(change_loading(false));
        dispatch(change_extra_modal_state("Thanks"));
        dispatch(change_modal("Inspiration"));
        dispatch(
          text_thanks(
            "Sit Tight! We'll send you a text with a link to your project when it's ready"
          )
        );
      } else {
        return false;
      }
    } catch (e) {
      const error = e as AxiosError;
      dispatch(change_loading(false));
      dispatch(set_common_reducer_state(common_reducer_initial_state));
      dispatch(set_config_reducer_state(config_reducer_initial_state));
      toast.error(error.message || "Something wrong in form, please try again");
    }
  };

export const update_email =
  (
    data: EmailStateI,
    setCookie: (
      name: "email",
      value: any,
      options?: CookieSetOptions | undefined
    ) => any,
    setIsRequestProcessing?: (value: boolean) => void | null
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      // setIsRequestProcessing(true);
      const formData = new FormData();
      formData.append("email", data.email);
      const response = await fetchingDataWithAxiosMiddleware(
        "POST",
        ServiceApi.update_email(),
        formData
      );
      if (response.status) {
        //setIsRequestProcessing(false);
        setCookie("email", data.email, { expires: expires_on });
      } else {
        return false;
      }
    } catch (e) {
      const error = e as AxiosError;
      dispatch(change_loading(false));
      toast.error(error.message || "Something wrong in form, please try again");
    } finally {
      // setIsRequestProcessing(false);
    }
  };
