import {ChangeMarkerI, FloorTileI, WallColorDataI, WallTileLogosPositionsI} from "../../Types";
import green_dote from "../../Assets/Images/Green_dote.png";
import marker from "../../Assets/Images/marker.png";
import {handler_marker_text, material_name_cut_number, vendor_name_cut_number} from "../Markers/markersConfig";

// export const viewer_navbar = (change_fullscreen_state: Dispatch<SetStateAction<boolean>>, dispatch: AppDispatch) => {
//
//   return [
//     // isMobile ? "gyroscope" : "",
//     {
//       id: "ios-fullscreen",
//       content: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-aspect-ratio" viewBox="0 0 16 16">
//                           <path d="M0 3.5A1.5 1.5 0 0 1 1.5 2h13A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5v-9zM1.5 3a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h13a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-13z"/>
//                           <path d="M2 4.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1H3v2.5a.5.5 0 0 1-1 0v-3zm12 7a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1 0-1H13V8.5a.5.5 0 0 1 1 0v3z"/>
//                        </svg>`,
//       title: "Hello world",
//       className: "custom-button",
//       onClick: (): void => {
//         change_fullscreen_state((prev: boolean) => !prev);
//       }
//     },
//     "caption",
//     "caption",
//     {
//       id: "my-button",
//       title: "Hello world",
//       className: "custom-button",
//       content: `
//                 <svg width="25" height="14" viewBox="0 0 25 14" fill="none" xmlns="http://www.w3.org/2000/svg">
//                     <g clip-path="url(#clip0_904_5194)">
//                       <path d="M6.3726 7C6.3726 3.55527 9.11602 0.762817 12.5001 0.762817C7.68283 0.762817 3.43511 3.23556 0.907715 7C3.43511 10.7644 7.68283 13.2372 12.5001 13.2372C9.11602 13.2372 6.3726 10.4447 6.3726 7Z" fill="white" stroke="white" stroke-miterlimit="10"/>
//                       <path d="M12.5 0.762817C15.8838 0.762817 18.627 3.55533 18.627 7C18.627 10.4447 15.8838 13.2372 12.5 13.2372C17.3172 13.2372 21.5647 10.7644 24.0921 7C21.5647 3.23562 17.3172 0.762817 12.5 0.762817Z" fill="white" stroke="white" stroke-miterlimit="10"/>
//                     </g>
//                     <defs>
//                       <clipPath id="clip0_904_5194">
//                         <rect width="25" height="14" fill="white"/>
//                       </clipPath>
//                     </defs>
//                 </svg>
//       `,
//       onClick: (): void => {
//         dispatch(change_showed_sphere());
//       }
//     }
//   ];
// };

let x = 0;
export const change_marker = (
    id: string,
    info: WallColorDataI | FloorTileI,
    logos_positions: WallTileLogosPositionsI,
    is_wall: string
): ChangeMarkerI => {
    x = (x + 1) / 2;
    return {
        id: `${id}-marker`,
        position: {
            yaw:
                id === "wall"
                    ? logos_positions.walls_marker[0]
                    : logos_positions.floor_marker[0],
            pitch:
                id === "wall"
                    ? logos_positions.walls_marker[1]
                    : logos_positions.floor_marker[1]
        },
        html: `
            <div 
                style="
                        position: relative;
                        padding: 5px;
                        min-width: 150px;
                        min-height: 150px;
                        color: white;
                        display:flex;
                        flex-direction: column;
                        justify-content: center
                      "
            >
                  <div id="container-${id}">
                        <div style="width: 100%; display: flex; align-items: center">
                              <img 
                                id="green_dote_marker_${id}"
                                src="${green_dote}" 
                                style="
                                        cursor: pointer;
                                        width: 25px;
                                        height: 18px; 
                                        object-fit: cover; 
                                        margin: 0 5px 0 0;
                                      " 
                                alt="green dote"
                              />
                          <p id="${id}-name" style="
                                                  font-weight: ${
                                                                    is_wall === "wall" &&
                                                                    id === "wall" ? "600"
                                                                        : is_wall === "tile" &&
                                                                        id === "tile"
                                                                            ? "600" : "400"
                                                                };
                                                  margin: 0; 
                                                  padding: 0
                                                  " >${
                                info.id
                                    ? handler_marker_text(material_name_cut_number, info.name)
                                    : "None"
                            }
                          </p>
                      </div>
                      ${
                                                    info.id
                                                        ? `<h1 id="${id}-vendor-name" style="
                                                                                          padding: 0;
                                                                                          color: #FFF;
                                                                                          font-size: 11px;
                                                                                          font-weight: 400;
                                                                                          margin: -2px 0 0 30px;
                                                                                          font-style: normal;
                                                                                          line-height: normal;
                                                                                          text-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
                                                                                        "
                                                                              >by ${
                                                            "vendorName" in info
                                                                ? handler_marker_text(
                                                                    vendor_name_cut_number,
                                                                    info.vendorName
                                                                )
                                                                : ""
                                                        } </h1>`
                                                        : ""
                                                }
                                                              <div
                                                                style="position:relative;width: 100%; "
                                                              >
                                                        ${
                                                    is_wall === "wall" && id === "wall" && info.id
                                                        ? `<img
                                                                id="wall_tag_marker"
                                                                src="${marker}"
                                                                style="
                                                                      position: absolute;
                                                                      cursor: pointer;
                                                                      left: 25px;
                                                                      bottom: -30px;
                                                                      width: 30px;
                                                                      height: 30px;
                                                                      object-fit: cover;
                                                                   "
                                                                alt="wall material"
                                                             />`
                                                            : is_wall === "tile" && id === "tile" && info.id
                                                                ? `<img   
                                                                      id="tile_tag_marker"
                                                                      src="${marker}"
                                                                      style="
                                                                              position: absolute;
                                                                              cursor: pointer;
                                                                              left: 25px;
                                                                              bottom: -30px;
                                                                              width: 30px;
                                                                              height: 30px;
                                                                              object-fit: cover;
                                                                             "
                                                                    alt="wall material"
                                                                  />`
                                        : ""
                            }
                      </div>  
                  </div>
               </div>
            `
    };
};

//zoomOut
// zoomRange
// zoomIn
// zoom = zoomOut + zoomRange + zoomIn
// moveLeft
// moveRight
// moveTop
// moveDown
// move = moveLeft + moveRight + moveTop + moveDown
// download
// description
// caption
// fullscreen

// 'autorotate',
// 'zoomOut',
// "zoomIn",

//               BUTTON EXAMPLE
//               {
//                 id: 'my-button',
//                 title: 'Hello world',
//                 className: 'custom-button',
//                 content: `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-alarm-fill" viewBox="0 0 16 16">
//   <path d="M6 .5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1H9v1.07a7.001 7.001 0 0 1 3.274 12.474l.601.602a.5.5 0 0 1-.707.708l-.746-.746A6.97 6.97 0 0 1 8 16a6.97 6.97 0 0 1-3.422-.892l-.746.746a.5.5 0 0 1-.707-.708l.602-.602A7.001 7.001 0 0 1 7 2.07V1h-.5A.5.5 0 0 1 6 .5zm2.5 5a.5.5 0 0 0-1 0v3.362l-1.429 2.38a.5.5 0 1 0 .858.515l1.5-2.5A.5.5 0 0 0 8.5 9V5.5zM.86 5.387A2.5 2.5 0 1 1 4.387 1.86 8.035 8.035 0 0 0 .86 5.387zM11.613 1.86a2.5 2.5 0 1 1 3.527 3.527 8.035 8.035 0 0 0-3.527-3.527z"/>
// </svg>`,
//                 onClick: function() {
//                   alert('Hello from custom button');
//                 }
//               },
//   "download",
