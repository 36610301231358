import React, {FC, useEffect} from "react";
import {useDispatch} from "react-redux";
import spaceStyles from "./spaces.module.scss";
import {AppDispatch} from "../../../Redux/store";
import {change_modal, is_clicked, set_random,} from "../../../Redux/Actions";
import {typedSelector} from "../../../Hooks/useTypedSelector";
import {choose_hard_coded_sphere} from "../../../Common/Config";
import {useAsyncActions} from "../../../Hooks/useActions";
// import { useAppContext } from "../../../Common/Provider/appProvider";

const Spaces: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {change_wall_or_tile_new, get_all_spheres} = useAsyncActions();
  const common = typedSelector((state) => state.common);
  const config = typedSelector((state) => state.config);
  const {spheres, sphere} = common;

  useEffect(() => {
    if (!common.spheres.length) {
      get_all_spheres();
    }
  }, []);


  return (
    <div
      className={spaceStyles.container}
      // className={`${spaceStyles.container} ${`overlay ${
      //   isRequestProcessing ? { pointerEvents: "none" } : ""
      // }`}`}
    >
      <p>DEMO ROOMS</p>
      <div className={spaceStyles.spaces_box1}>
        <div
          onClick={(e) => {
            e.stopPropagation();
            choose_hard_coded_sphere(
              sphere.id,
              dispatch,
              common,
              config,
              spheres[0],
              change_wall_or_tile_new
              // setIsRequestProcessing
            );
            dispatch(set_random(false));
          }}
          className={spaceStyles.one}
        >
          <img
            className={spaceStyles.livingRoom}
            src={spheres[0]?.thumbnailUrl}
            alt="livingRoom"
          />
          <div className={spaceStyles.gradient}/>
          {/*<p className={spaceStyles.livingRoom_text}>Bedroom</p>*/}
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            choose_hard_coded_sphere(
              sphere.id,
              dispatch,
              common,
              config,
              spheres[3],
              change_wall_or_tile_new
              // setIsRequestProcessing
            );
            dispatch(set_random(false));
          }}
          className={spaceStyles.two}
        >
          <img
            className={spaceStyles.bathroom}
            src={spheres[3]?.thumbnailUrl}
            alt="bathroom"
          />
          <div className={spaceStyles.gradient}/>
          {/*<p className={spaceStyles.bathroom_text}>Office</p>*/}
        </div>
      </div>
      <div className={spaceStyles.spaces_box2}>
        <div
          onClick={(e) => {
            e.stopPropagation();
            choose_hard_coded_sphere(
              sphere.id,
              dispatch,
              common,
              config,
              spheres[2],
              change_wall_or_tile_new
              // setIsRequestProcessing
            );
            dispatch(set_random(false));
          }}
          className={spaceStyles.three}
        >
          <img
            className={spaceStyles.office}
            src={spheres[2]?.thumbnailUrl}
            alt="office"
          />
          <div className={spaceStyles.gradient}/>
          {/*<p className={spaceStyles.office_text}>Dining Room</p>*/}
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            choose_hard_coded_sphere(
              sphere.id,
              dispatch,
              common,
              config,
              spheres[1],
              change_wall_or_tile_new
              // setIsRequestProcessing
            );
            dispatch(set_random(false));
          }}
          className={spaceStyles.four}
        >
          <img
            className={spaceStyles.bedroom}
            src={spheres[1]?.thumbnailUrl}
            alt="bedroom"
          />
          <div className={spaceStyles.gradient}/>
          {/*<p className={spaceStyles.bedroom_text}>Living Room</p>*/}
        </div>
      </div>
      <div className={spaceStyles.div}>
        {/*<div className={spaceStyles.or}>OR</div>*/}
        {/*<div*/}
        {/*  className={spaceStyles.button}*/}
        {/*  onClick={(e) => {*/}
        {/*    e.stopPropagation();*/}
        {/*    dispatch(change_extra_modal_state("Capture"));*/}
        {/*  }}*/}
        {/*>*/}
        {/*  See in My Space*/}
        {/*</div>*/}
        <p
          onClick={(e) => {
            e.stopPropagation();
            dispatch(is_clicked(false));
            dispatch(change_modal("close"));
          }}
        >
          Back
        </p>
      </div>
    </div>
  );
};

export default Spaces;
