interface ValidationRegExpI {
  email: RegExp;
  numbers: RegExp;
}

export const validationRegExp: ValidationRegExpI = {
  // eslint-disable-next-line
  email: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
  numbers: /^[-+\s]*([0-9][-+\s]*){9,}$/
};

export const isValidate = (type: string, value: string): boolean => {
  switch (type) {
    case "email":
      return validationRegExp.email.test(value);
    case "number":
      return validationRegExp.numbers.test(value);
    default:
      return false;
  }
};
