import { PayloadType } from "../../../Types";

export const SET_STILL_PHOTO_BASE_64: string = "SET STILL PHOTO BASE 64";
export const CLEAR_STILL_PHOTO_BASE_64: string = "CLEAR STILL PHOTO BASE 64";
export const SET_INSPIRATION_IMAGE_BASE_64: string =
  "SET INSPIRATION IMAGE BASE 64";
export const CLEAR_INSPIRATION_IMAGE_BASE_64: string =
  "CLEAR INSPIRATION IMAGE BASE 64";

export const set_still_photo_base_64 = (
  payload: string | ArrayBuffer
): PayloadType<string | ArrayBuffer> => {
  return {
    type: SET_STILL_PHOTO_BASE_64,
    payload,
  };
};

export const clear_still_photo_base_64 = (): { type: string } => {
  return { type: CLEAR_STILL_PHOTO_BASE_64 };
};

export const set_inspiration_image_base_64 = (
  payload: string | ArrayBuffer
): PayloadType<string | ArrayBuffer> => {
  return {
    type: SET_INSPIRATION_IMAGE_BASE_64,
    payload,
  };
};
export const clear_inspiration_image_base_64 = (): { type: string } => {
  return { type: CLEAR_INSPIRATION_IMAGE_BASE_64 };
};
