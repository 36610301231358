import { LookController } from "./look_controller";
import { SphereController } from "./sphere_controller";

const Look_controller = new LookController(),
  Sphere_controller = new SphereController();


export {
  Look_controller,
  Sphere_controller
};