import { AnyAction } from "redux";
import { RCommonStateI } from "../../Types";
import {
  common_reducer_initial_look_data,
  common_reducer_initial_result_data,
  common_reducer_initial_sphere_data,
  common_reducer_initial_state
} from "../../Common/Config/reducersConfig";
import {
  CHANGE_LOOK_TILE,
  CHANGE_LOOK_WALL,
  CLEAR_LOADING_TIME,
  CLEAR_RESULT_CHECKER,
  CLEAR_RESULT_DATA,
  CLEAR_SELECTED_LOOK,
  CLEAR_SELECTED_SPHERE,
  CLEAR_UPLOAD_LOOK_CHECKER,
  RESULT_CHECKER,
  SELECT_LOOK,
  SELECT_SPHERE,
  SET_ALL_LOOKS,
  SET_ALL_SPHERES,
  SET_COMMON_REDUCER_STATE,
  SET_CONTINUE_RANDOM_TILES,
  SET_CONTINUE_RANDOM_WALLS,
  SET_LOADING_TIME,
  SET_NEW_SPHERE_URL,
  SET_NEW_STILL_PHOTO_URL,
  SET_OLD_SPHERE_URL,
  SET_OLD_STILL_PHOTO_URL,
  SET_RANDOM_TILES,
  SET_RANDOM_WALLS,
  SET_RESULT_DATA,
  SET_STILL_PHOTO_ENDPOINT,
  SET_WALL_TILE_LOGOS_POSITIONS,
  STILL_PHOTO_LOGO_LOCATIONS,
  UPLOAD_LOOK_CHECKER,
  UPLOADED_LOOK_URL
} from "../Actions";

const initialState: RCommonStateI = common_reducer_initial_state;

export const commonReducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case SET_ALL_LOOKS:
      return { ...state, looks: action.payload || [] };
    case SET_RANDOM_WALLS:
      return { ...state, random_walls: action.payload || [] };
    case SET_RANDOM_TILES:
      return { ...state, random_tiles: action.payload || [] };
    case SET_ALL_SPHERES:
      return { ...state, spheres: action.payload || [] };
    case SET_COMMON_REDUCER_STATE:
      return { ...state, ...action.payload };
    case SET_CONTINUE_RANDOM_WALLS:
      return { ...state, next_random_walls: action.payload };
    case SET_CONTINUE_RANDOM_TILES:
      return { ...state, next_random_tiles: action.payload };
    case CHANGE_LOOK_WALL:
      return {
        ...state,
        look: {
          ...state.look,
          wallColor: {
            ...action.payload,
            is_look_wall: false,
          },
        },
      };
    case CHANGE_LOOK_TILE:
      return {
        ...state,
        look: {
          ...state.look,
          floorTile: {
            ...action.payload,
            is_look_tile: false,
          },
        },
      };
    case SELECT_LOOK:
      return {
        ...state,
        look: {
          ...action.payload,
          wallColor: {
            ...action.payload?.wallColor,
            is_look_wall: true,
          },
          floorTile: {
            ...action.payload?.floorTile,
            is_look_tile: true,
          },
        },
      };
    case SELECT_SPHERE:
      return { ...state, sphere: action.payload };
    case RESULT_CHECKER:
      return { ...state, result_checker_state: action.payload };
    case CLEAR_RESULT_CHECKER:
      return {
        ...state,
        result_checker_state: {
          called_count: 0,
          called_id: {
            ID: 0,
            minutesRemaining: 0,
            secondsRemaining: 0,
          },
        },
      };
    case UPLOAD_LOOK_CHECKER:
      return { ...state, upload_look_checker_state: action.payload };
    case CLEAR_UPLOAD_LOOK_CHECKER:
      return {
        ...state,
        upload_look_checker_state: {
          called_count: 0,
          called_id: {
            ID: 0,
            minutesRemaining: 0,
            secondsRemaining: 0,
          },
        },
      };
    case SET_OLD_STILL_PHOTO_URL:
      return {
        ...state,
        result: {
          ...state.result,
          old_still_photo_url: action.payload,
          new_sphere_url: "",
          old_sphere_url: "",
          sphere_endpoint: "",
        },
      };
    case SET_NEW_STILL_PHOTO_URL:
      return {
        ...state,
        result: { ...state.result, new_still_photo_url: action.payload },
      };
    case SET_STILL_PHOTO_ENDPOINT:
      return {
        ...state,
        result: {
          ...state.result,
          still_photo_endpoint: action.payload,
        },
      };
    case SET_OLD_SPHERE_URL:
      return {
        ...state,
        result: {
          ...state.result,
          sphere_endpoint: action.payload.endpoint,
          old_sphere_url: action.payload.url,
          old_still_photo_url: "",
          new_still_photo_url: "",
        },
      };

    case SET_NEW_SPHERE_URL:
      return {
        ...state,
        result: {
          ...state.result,
          new_sphere_url: action.payload,
        },
      };
    case SET_WALL_TILE_LOGOS_POSITIONS:
      return { ...state, logos_positions: { ...action.payload } };
    case CLEAR_RESULT_DATA:
      return { ...state, result: { ...common_reducer_initial_result_data } };
    case CLEAR_SELECTED_LOOK:
      return { ...state, look: { ...common_reducer_initial_look_data } };
    case CLEAR_SELECTED_SPHERE:
      return { ...state, sphere: { ...common_reducer_initial_sphere_data } };
    case UPLOADED_LOOK_URL:
      return { ...state, uploaded_look_url: action.payload };
    case SET_LOADING_TIME:
      return { ...state, loading_time: action.payload };
    case CLEAR_LOADING_TIME:
      return {
        ...state,
        loading_time: { id: 0, minutesRemaining: 0, secondsRemaining: 0 },
      };
    case SET_RESULT_DATA:
      return { ...state, result: action.payload };
    case STILL_PHOTO_LOGO_LOCATIONS:
      return { ...state, still_photo_logo_location: action.payload }
    default:
      return state;
  }
};
