import { ChangeEvent, FC, useState } from "react";
import reviewStyle from "./rateAndReview.module.scss";
import { useAsyncActions } from "../../../Hooks/useActions";
import toast from "react-hot-toast";
import { useCookies } from "react-cookie";

const RateAndReview: FC = () => {
  const [review, setReview] = useState<string>("");
  const [rating, setRating] = useState<number>(0);
  const [hover, setHover] = useState(0);
  const { rate_and_review } = useAsyncActions();
  const [, setCookie] = useCookies(["rateReview"]);

  const handleRateOrReview = () => {
    if (rating || review) {
      rate_and_review(
        {
          numberOfStars: rating || "",
          comment: review,
        },
        setCookie
      );
    } else {
      toast.error("Rate your experience or leave a comment");
    }
  };

  return (
    <div className={reviewStyle.component}>
      <div className={reviewStyle.box}>
        <div
          onClick={(e) => e.stopPropagation()}
          className={reviewStyle.content}
        >
          <p className={reviewStyle.rate_text}>Rate Your Experience</p>
          <div className="star-rating">
            {[...Array(5)].map((star, index) => {
              index += 1;
              return (
                <button
                  type="button"
                  key={index}
                  className={
                    index <= (hover || rating)
                      ? reviewStyle.on
                      : reviewStyle.off
                  }
                  onClick={() => setRating(index)}
                  onMouseEnter={() => setHover(index)}
                  onMouseLeave={() => setHover(rating)}
                >
                  <span className={reviewStyle.star}>&#9733;</span>
                </button>
              );
            })}
          </div>
          <div className={reviewStyle.text}>
            <p>Leave a comment</p>
          </div>
          <textarea
            rows={8}
            value={review}
            className={reviewStyle.input}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
              setReview(e.target.value);
            }}
          />
          <div className={reviewStyle.button} onClick={handleRateOrReview}>
            Submit
          </div>
        </div>
      </div>
    </div>
  );
};

export default RateAndReview;
