import React, { useCallback, useEffect, useRef, useState } from "react";
import circleStyle from "./circles.module.scss";
import blank from "../../../Assets/Icons/blank.svg";
import blankActive from "../../../Assets/Icons/blank1.svg";
import { useAsyncActions } from "../../../Hooks/useActions";
import { TileTypeDataI, WallColorDataI } from "../../../Types";
import { typedSelector } from "../../../Hooks/useTypedSelector";
import { tile_blank, wall_blank } from "../../../Common/Config";
import {
    clear_loading_time,
    set_current_process_type,
    set_look,
    set_new_spheres_status,
    shared_page
} from "../../../Redux/Actions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../Redux/store";
import { set_inspiration_image_base_64 } from "../../../Redux/Actions/CommonActions/assetsAction";
import { ServiceDataControl } from "../../../Services";

// import { useAppContext } from "../../../Common/Provider/appProvider";

interface CustomHorizontalScrollProps {
    width: number;
    onFetchNextData: () => void;
    hasMore: boolean;
    data: WallColorDataI[] | TileTypeDataI[] | [];
    material_type: string;
}

const CustomHorizontalScroll: React.FC<CustomHorizontalScrollProps> = ({
                                                                           width,
                                                                           onFetchNextData,
                                                                           hasMore,
                                                                           data,
                                                                           material_type
                                                                       }) => {
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const [isFetching, setIsFetching] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const {change_wall_or_tile_new} = useAsyncActions();
    // const { isRequestProcessing, setIsRequestProcessing } = useAppContext();

    const common = typedSelector((state) => state.common);
    const config = typedSelector((state) => state.config);
    const {look, result} = common;
    const {sphere_statuses} = config;

    const get_default_look_data = useCallback(() => {
        return JSON.parse(String(sessionStorage.getItem("default_look")));
    }, [look?.url]);

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;

        const handleScroll = () => {
            if (hasMore && !isFetching && scrollContainer) {
                const {scrollLeft, scrollWidth, clientWidth} = scrollContainer;
                const scrolledPercentage =
                    (scrollLeft / (scrollWidth - clientWidth)) * 100;

                if (scrolledPercentage > 60 && material_type === "wall") {
                    setIsFetching(true);
                    onFetchNextData();
                }

                if (scrolledPercentage > 60 && material_type === "tile") {
                    setIsFetching(true);
                    onFetchNextData();
                }
            }
        };

        scrollContainer?.addEventListener("scroll", handleScroll);
        return () => {
            scrollContainer?.removeEventListener("scroll", handleScroll);
        };
    }, [hasMore, onFetchNextData, isFetching]);

    useEffect(() => {
        // Reset fetching state when new data is received
        setIsFetching(false);
    }, [data]);


    return (
        <div
            ref={scrollContainerRef}
            style={{
                width: `${width}px`,
                overflow: "hidden",
                display: "flex",
                overflowX: "auto",
                scrollBehavior: "smooth"
            }}
            // style={`overlay
            //   ${
            //     isRequestProcessing
            //       ? {
            //           pointerEvents: "none",
            //           width: `${width}px`,
            //           overflow: "hidden",
            //           display: "flex",
            //           overflowX: "auto",
            //           scrollBehavior: "smooth",
            //         }
            //       : {
            //           width: `${width}px`,
            //           overflow: "hidden",
            //           display: "flex",
            //           overflowX: "auto",
            //           scrollBehavior: "smooth",
            //         }
            //   }`}
        >
            <div
                style={{
                    whiteSpace: "nowrap",
                    display: "flex",
                    alignItems: "baseline"
                }}
            >
                <div
                    className={`${circleStyle.circle} `}
                    onClick={() => {
                        if (sphere_statuses.new_sphere) {
                            if (
                                (material_type === "wall" && look?.wallColor.id !== 0) ||
                                (material_type === "tile" && look?.floorTile.id !== 0)
                            ) {
                                dispatch(clear_loading_time());
                                dispatch(set_new_spheres_status());
                                if (config.is_shared_page) {
                                    ServiceDataControl.set_session_storage_item<string>("saved_look_url", look.url);
                                    dispatch(
                                        set_look({...look, url: "", urlWithLogos: "", id: 0})
                                    );
                                    dispatch(set_inspiration_image_base_64(""));
                                    dispatch(shared_page(false));

                                }
                                dispatch(set_current_process_type("wall_tile"));
                                change_wall_or_tile_new({
                                    wall: material_type === "wall" ? wall_blank : look?.wallColor,
                                    tile: material_type === "tile" ? tile_blank : look?.floorTile,
                                    sphere_endpoint: !config.is_still_photo
                                        ? result.sphere_endpoint
                                        : result.still_photo_endpoint,
                                    process_type: !config.is_still_photo
                                        ? "hardcodedsphere"
                                        : "stillphoto",
                                    interactionSource: "suggested",
                                    common,
                                    config
                                    // setIsRequestProcessing: setIsRequestProcessing,
                                });
                            }
                        }
                    }}
                >
                    {material_type === "wall" ? (
                        look.wallColor.id !== 0 ? (
                            <img
                                src={blank}
                                className={
                                    (material_type === "wall" && circleStyle.circle_color) ||
                                    circleStyle.circle_color
                                }
                                alt="No style"
                            />
                        ) : (
                            <img
                                src={blankActive}
                                className={
                                    (material_type === "wall" &&
                                        `${circleStyle.circle_color} ${circleStyle.selected_circle_none}`) ||
                                    circleStyle.circle_color
                                }
                                alt="No style active"
                            />
                        )
                    ) : look.floorTile.id !== 0 ? (
                        <img
                            src={blank}
                            className={
                                (material_type === "tile" && circleStyle.circle_color) ||
                                circleStyle.circle_color
                            }
                            alt="No style"
                        />
                    ) : (
                        <img
                            src={blankActive}
                            className={
                                (material_type === "tile" &&
                                    `${circleStyle.circle_color} ${circleStyle.selected_circle_none}`) ||
                                circleStyle.circle_color
                            }
                            alt="No style active"
                        />
                    )}
                    <div
                        className={
                            (material_type === "wall" &&
                                look.wallColor.id === 0 &&
                                `${circleStyle.color_name} ${circleStyle.active}`) ||
                            (material_type === "tile" &&
                                look.floorTile.id === 0 &&
                                `${circleStyle.color_name} ${circleStyle.active}`) ||
                            circleStyle.color_name
                        }
                    >
                        {material_type === "wall" ? "" : "None"}
                    </div>
                </div>
                {/* TODO HERE (DOWN) */}
                <div
                    onClick={() => {
                        if (sphere_statuses.new_sphere) {
                            if (
                                (material_type === "wall" &&
                                    look.wallColor.id !==
                                    get_default_look_data()?.wallColor.id) ||
                                (material_type === "tile" &&
                                    look.floorTile.id !== get_default_look_data()?.floorTile.id)
                            ) {
                                dispatch(clear_loading_time());
                                dispatch(set_new_spheres_status());
                                if (config.is_shared_page) {
                                    ServiceDataControl.set_session_storage_item<string>("saved_look_url", look.url);
                                    dispatch(
                                        set_look({...look, url: "", urlWithLogos: "", id: 0})
                                    );
                                    dispatch(set_inspiration_image_base_64(""));
                                    dispatch(shared_page(false));
                                }
                                dispatch(set_current_process_type("wall_tile"));
                                change_wall_or_tile_new({
                                    wall:
                                        material_type === "wall"
                                            ? get_default_look_data()?.wallColor
                                            : look.wallColor,
                                    tile:
                                        material_type === "tile"
                                            ? get_default_look_data()?.floorTile
                                            : look.floorTile,
                                    sphere_endpoint: !config.is_still_photo
                                        ? result.sphere_endpoint
                                        : result.still_photo_endpoint,
                                    process_type: !config.is_still_photo
                                        ? "hardcodedsphere"
                                        : "stillphoto",
                                    interactionSource: "suggested",
                                    common,
                                    config
                                    // setIsRequestProcessing: setIsRequestProcessing,
                                });
                            }
                        }
                    }}
                    className={circleStyle.circle}
                >
                    {material_type === "wall" ? (
                        !!get_default_look_data()?.wallColor.name ? <div
                          className={
                              look.wallColor.id === get_default_look_data()?.wallColor.id
                                ? `${circleStyle.circle_color} ${circleStyle.selected_circle}`
                                : circleStyle.circle_color
                          }
                          style={{
                              background:
                                "hexCode" in  get_default_look_data()?.wallColor
                                  ? get_default_look_data()?.wallColor.hexCode
                                  : ""
                          }}
                        />
                          : <></>
                    ) : material_type === "tile" ? (
                        !!get_default_look_data()?.floorTile.name ? <img
                          className={
                              look.floorTile.id === get_default_look_data()?.floorTile.id
                                ? `${circleStyle.circle_color} ${circleStyle.selected_circle}`
                                : circleStyle.circle_color
                          }
                          src={
                              "floorIcon" in  get_default_look_data()?.floorTile
                                ? get_default_look_data()?.floorTile.floorIcon
                                : ""
                          }
                          alt="floor type"
                        />
                          : <></>
                    ) : (
                        <></>
                    )}
                    <div
                        className={
                            (material_type === "wall" &&
                                look.wallColor.id === get_default_look_data()?.wallColor.id) ||
                            (material_type === "tile" &&
                                look.floorTile.id === get_default_look_data()?.floorTile.id)
                                ? `${circleStyle.color_name} ${circleStyle.active}`
                                : circleStyle.color_name
                        }
                    >
                        {
                            material_type === "wall"
                                ? ""
                                : get_default_look_data()?.floorTile.type
                        }
                    </div>
                </div>
                {/* TODO HERE (UP) */}
                {data.map((circle, index) => (
                    <div
                        onClick={() => {
                            if (sphere_statuses.new_sphere) {
                                if (
                                    (material_type === "wall" &&
                                        look.wallColor.id !== circle.id) ||
                                    (material_type === "tile" && look.floorTile.id !== circle.id)
                                ) {
                                    dispatch(clear_loading_time());
                                    dispatch(set_new_spheres_status());
                                    if (config.is_shared_page) {
                                        ServiceDataControl.set_session_storage_item<string>("saved_look_url", look.url);
                                        dispatch(
                                            set_look({...look, url: "", urlWithLogos: "", id: 0})
                                        );
                                        dispatch(set_inspiration_image_base_64(""));
                                        dispatch(shared_page(false));
                                    }
                                    dispatch(set_current_process_type("wall_tile"));
                                    change_wall_or_tile_new({
                                        wall: material_type === "wall" ? circle : look.wallColor,
                                        tile: material_type === "tile" ? circle : look.floorTile,
                                        sphere_endpoint: !config.is_still_photo
                                            ? result.sphere_endpoint
                                            : result.still_photo_endpoint,
                                        process_type: !config.is_still_photo
                                            ? "hardcodedsphere"
                                            : "stillphoto",
                                        interactionSource: "suggested",
                                        common,
                                        config
                                        // setIsRequestProcessing: setIsRequestProcessing,
                                    });
                                }
                            }
                        }}
                        key={`circle_${index}`}
                        className={circleStyle.circle}
                    >
                        {material_type === "wall" ? (
                            <div
                                className={
                                    circle.id === look.wallColor.id
                                        ? `${circleStyle.circle_color} ${circleStyle.selected_circle}`
                                        : circleStyle.circle_color
                                }
                                style={{
                                    background: "hexCode" in circle ? circle?.hexCode : ""
                                }}
                            />
                        ) : material_type === "tile" ? (
                            <img
                                className={
                                    circle.id === look.floorTile.id
                                        ? `${circleStyle.circle_color} ${circleStyle.selected_circle}`
                                        : circleStyle.circle_color
                                }
                                src={"floorIcon" in circle ? circle?.floorIcon : ""}
                                alt="floor type"
                            />
                        ) : (
                            <></>
                        )}
                        <div
                            className={
                                (material_type === "wall" && look.wallColor.id === circle.id) ||
                                (material_type === "tile" && look.floorTile.id === circle.id)
                                    ? `${circleStyle.color_name} ${circleStyle.active}`
                                    : circleStyle.color_name
                            }
                        >
                            {material_type === "wall" ? "" : circle.type}
                        </div>
                    </div>
                ))}
                {isFetching && (
                    <p
                        className={circleStyle.loading}
                        style={{
                            margin: 0,
                            fontFamily: "Azo-Sans",
                            color: "#00140A"
                        }}
                    >
                        Loading ...
                    </p>
                )}
            </div>
        </div>
    );
};

export default CustomHorizontalScroll;
