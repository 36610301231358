import { FC } from "react";
import bidStyle from "./bids.module.scss";
import { change_modal, text_thanks } from "../../../Redux/Actions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../Redux/store";

const GetBids: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  return (
    <div className={bidStyle.bids_component}>
      <div className={bidStyle.component}>
        <div onClick={e => e.stopPropagation()} className={bidStyle.box}>
          <div className={bidStyle.content}>
            <div className={bidStyle.text}>
              <p>Select the project you</p>
              <p>want bids for:</p>
            </div>
            <div
              className={bidStyle.button}
              onClick={() => {
                dispatch(change_modal("Thanks"));
                dispatch(
                  text_thanks(
                    "Please check your email to confirm your bid request."
                  )
                );
                window.open(
                  " http://match.angi.com/dmtm/38861120?m=dospace12345678",
                  "_blank"
                );
              }}
            >
              Painting
            </div>
            <div
              className={bidStyle.button}
              onClick={() => {
                dispatch(change_modal("Thanks"));
                dispatch(
                  text_thanks(
                    "Please check your email to confirm your bid request."
                  )
                );
                window.open(
                  "http://match.angi.com/dmtm/38861417?m=dospace12345678",
                  "_blank"
                );
              }}
            >
              Flooring
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetBids;
