import { FC, useEffect, useState } from "react";
import loadingStyle from "./loading.module.scss";
import Footer from "../../Footer/footer";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { typedSelector } from "../../../Hooks/useTypedSelector";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../Redux/store";
import {
  change_extra_modal_state,
  // change_loading,
} from "../../../Redux/Actions";

const Loading: FC<{ custom?: boolean }> = ({ custom = false }) => {
  const dispatch = useDispatch<AppDispatch>();
  const config = typedSelector((state) => state.config);
  // const common = typedSelector((state) => state.common);
  const { loading } = config;
  // const { uploaded_look_url } = common;

  const applyTime = typedSelector(
    (state) => state.common.result_checker_state.called_id
  );
  const uploadTime = typedSelector(
    (state) => state.common.upload_look_checker_state.called_id
  );

  let timesRemaining = uploadTime.ID ? uploadTime : applyTime;


  const [percentage, setPercentage] = useState<number>(1);
  const [min, setMin] = useState<number>(0);
  const [sec, setSec] = useState<number>(0);
  const [time, setTime] = useState<number>(custom ? 30 : 0);

  useEffect(() => {
    
    let interval: NodeJS.Timeout | null = null;
    if (timesRemaining.minutesRemaining || timesRemaining.secondsRemaining) {
      interval = setInterval(() => {
        if (!min && !sec) {
          setMin(timesRemaining.minutesRemaining);
          setSec(timesRemaining.secondsRemaining);
          setTime(
            timesRemaining.minutesRemaining * 60 +
              timesRemaining.secondsRemaining
          );
        }

        if (sec === 0 && min > 0) {
          setMin((time: number) => time - 1);
          setSec(sec + 60);
        }
        setPercentage((time: number) => time + 1);
        sec && setSec((time: number) => time - 1);
      }, 1000);

    }
   
    // if (sec === 0 && min === 0 && percentage !== 0 && percentage === time) {

    //   clearInterval(interval!);
    //   setTime(0);
    //   setPercentage(0);
    // }

    if (!loading && !custom) {
      clearInterval(interval!);
      setSec(0);
      setPercentage(0);
    }

    return () => {
      clearInterval(interval!);
    };
  }, [percentage, timesRemaining]);

  // const handler_loading = useRef<Function>((num: number) => {
  //   if (num !== 100 || (!uploaded_look_url && num !== 100)) {
  //     return setPercentage(num + 1);
  //   } else {
  //     num = 0;
  //     return setPercentage(num + 1);
  //   }
  // });

  // useEffect(() => {
  //   if (loading) {
  //     setTimeout(() => {
  //       handler_loading.current(percentage);
  //     }, 1000);
  //   }
  //   if (loading && percentage) {
  //     setTimeout(() => {
  //       setSec(sec + 1);
  //     }, 1000);
  //   }
  //   if (!loading && uploaded_look_url) {
  //     setSec(0);
  //     dispatch(change_loading(false));
  //     setPercentage(0);
  //   }
  // }, [percentage, loading, sec]);

  if (!loading && !custom) {
    return <></>;
  }

  return (
    <div className={loadingStyle.container}>
      <div className={loadingStyle.upper_box}>
        Your Space is Loading...
        <div className={loadingStyle.progress_box}>
          <CircularProgressbar
            value={percentage}
            text={time > 30 ? `${min}:${sec}` : ``}
            minValue={0}
            maxValue={time > 3 ? time : 100}
            strokeWidth={50}
            // styles={buildStyles({
            //   strokeLinecap: "butt",
            //   pathColor: "#34E780",
            //   trailColor: "none",
            // })}
            styles={buildStyles({
              rotation: 0,
              strokeLinecap: "butt",
              textSize: "20px",
              pathTransitionDuration: 1,
              pathColor: `#59D07F`,
              textColor: "#fff",
              trailColor: "none",
            })}
          />
        </div>
      </div>
      {sec >= 2 && (
        <div className={loadingStyle.bottom_box}>
          <div className={loadingStyle.text_me_box}>
            <p className={loadingStyle.rush}>In a rush?</p>
            <div
              className={loadingStyle.button}
              onClick={() => dispatch(change_extra_modal_state("LinkMe"))}
            >
              <p>Text Me When</p>
              <p>It’s Ready</p>
            </div>
          </div>
          <Footer classname={loadingStyle.footer} />
        </div>
      )}
    </div>
  );
};

export default Loading;
