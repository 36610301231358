import FormData from "form-data";
import toast from "react-hot-toast";
import { AppDispatch } from "../../store";
import { ChangeWallORTileI, LastViewedStyleI, RCommonStateI, RConfigStateI, ResultCheckerI } from "../../../Types";
import { AxiosError, CancelToken } from "axios";
import {
  change_extra_modal_state,
  change_loading,
  change_modal,
  change_reminder,
  change_showed_sphere,
  is_clicked,
  set_config_reducer_state,
  set_current_process_type,
  set_look_type
} from "../CommonActions/configAction";
import { fetchingDataWithAxiosMiddleware } from "./commonAction";
import { ServiceApi, ServiceDataControl } from "../../../Services";
import {
  clear_loading_time,
  set_common_reducer_state,
  set_loading_time,
  upload_look_checker
} from "../CommonActions/commonAction";
import { set_all_looks, set_look, uploaded_look_url } from "../CommonActions/lookAction";
import { set_new_sphere_url, set_new_spheres_status } from "../CommonActions/sphereAction";
import { set_new_still_photo_url } from "../CommonActions/stillPhotoAction";
import { hard_coded_sphere_first_open } from "../../../Common/Config";
import { Sphere_controller } from "../../../Controllers";
import { common_reducer_initial_state, config_reducer_initial_state } from "../../../Common/Config/reducersConfig";

export const upload_look_new =
  (
    file: File, //, setIsRequestProcessing: (value: boolean) => void | null
    common: RCommonStateI,
    config: RConfigStateI
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      ServiceDataControl.set_last_viewed_style({
        look: common.look,
        result: common.result,
        config,
      });
      // setIsRequestProcessing(true);
      // dispatch(change_loading(true));
      dispatch(change_modal());
      dispatch(clear_loading_time())
      dispatch(set_new_spheres_status());
      dispatch(set_look_type(true));
      // dispatch(set_old_spheres_status());
      dispatch(set_new_sphere_url(""));
      dispatch(set_new_still_photo_url(""));
      const formData = new FormData();
      formData.append("file", file);
      const response = await fetchingDataWithAxiosMiddleware(
        "POST",
        ServiceApi.upload_look_new(),
        formData
      );
      dispatch(change_extra_modal_state());
      if (response.status) {
        const data = await response.data;
        // setIsRequestProcessing(false);
        dispatch(change_reminder(false));
        dispatch(set_current_process_type("upload look"));
        dispatch(
          set_loading_time({
            id: data.ID,
            minutesRemaining: data.minutesRemaining,
            secondsRemaining: data.secondsRemaining + 10,
          })
        );
        dispatch(
          upload_look_checker({
            called_count: 1,
            called_id: {
              ...data,
            },
          })
        );
      }
    } catch (e) {
      const error = e as AxiosError;
      toast.error(error.message || "Something went wrong with uploading");
      // dispatch(set_common_reducer_state(common_reducer_initial_state));
      // dispatch(set_config_reducer_state(config_reducer_initial_state));
      ServiceDataControl.get_last_viewed_style(dispatch);
      dispatch(change_extra_modal_state());
      dispatch(is_clicked(false))
      dispatch(change_modal());
      dispatch(change_loading(false));
    }
  };

let upload_check_timer: NodeJS.Timeout | undefined = undefined;

export const upload_look =
  (
    upload_look_checker_state: ResultCheckerI,
    upload_checker_source: CancelToken,
    change_wall_or_tile_new: (
      payload: ChangeWallORTileI
    ) => (dispatch: AppDispatch) => Promise<void>,
    common: RCommonStateI,
    config: RConfigStateI
    // setIsRequestProcessing: (value: boolean) => void | null
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      dispatch(change_showed_sphere(true));
      // setIsRequestProcessing(true);
      const sphere = await fetchingDataWithAxiosMiddleware(
        "GET",
        ServiceApi.get_upload_look_result(
          `${upload_look_checker_state.called_id.ID}`
        ),
        undefined,
        upload_checker_source
      );
      // throw new Error("Error for test");

      if (sphere.status === 200) {
        // setIsRequestProcessing(false);
        dispatch(change_modal());
        // dispatch(clear_result_data())
        if (upload_check_timer) {
          clearTimeout(upload_check_timer);
        }
        const data = await sphere.data;
        const { url, urlWithLogos, floorTile, wallColor, id } = data;
        dispatch(uploaded_look_url(url));
        const uploaded_look_info = {
          id,
          url,
          urlWithLogos,
          floorTile: {
            id: floorTile.id || 0,
            name: floorTile.name || "",
            type: floorTile.type || "",
            code: floorTile.code || "",
            logo: floorTile.logo || "",
            floorIcon: floorTile.floorIcon || "",
            is_look_tile: true,
            urlOnVendorPage: floorTile.urlOnVendorPage || "",
            vendorName: floorTile.vendorName || "",
          },
          wallColor: {
            id: wallColor.id || 0,
            name: wallColor.name || "",
            type: "",
            hexCode: wallColor.hexCode || "",
            skuNumber: wallColor.skuNumber || "",
            logo: wallColor.logo || "",
            rgbCode: wallColor.rgbCode || [0, 0, 0],
            is_look_wall: true,
            urlOnVendorPage: wallColor.urlOnVendorPage || "",
            vendorName: wallColor.vendorName || "",
          },
        };
        dispatch(
          upload_look_checker({
            called_count: 0,
            called_id: {
              ID: 0,
              minutesRemaining: 0,
              secondsRemaining: 0,
            },
          })
        );
        dispatch(set_look(uploaded_look_info));
        ServiceDataControl.default_look_info(uploaded_look_info);
        if (
          !common?.result?.sphere_endpoint &&
          !common?.result?.still_photo_endpoint
        ) {
          Sphere_controller.handler_sphere(
            hard_coded_sphere_first_open,
            dispatch,
            common.look,
            config.look_uploaded
          );
        }
        const last_viewed_style =
          ServiceDataControl.get_session_storage_item<LastViewedStyleI>(
            "last_viewed_style"
          );
        change_wall_or_tile_new({
          tile: uploaded_look_info.floorTile,
          wall: uploaded_look_info.wallColor,
          sphere_endpoint: common?.result?.sphere_endpoint
            ? common?.result?.sphere_endpoint
            : common?.result?.still_photo_endpoint
            ? common?.result?.still_photo_endpoint
            : "demo_sample_1",
          process_type: !config.is_still_photo
            ? "hardcodedsphere"
            : "stillphoto",
          interactionSource: "customLook",
          common: {
            ...common,
            look: last_viewed_style?.look || common.look,
            result: last_viewed_style?.result || common.result,
          },
          config: last_viewed_style?.config || config,
          // setIsRequestProcessing: setIsRequestProcessing,
        });
        // dispatch(change_loading(false));
      } else if (sphere.status === 202 && !config.is_reminder) {
        // if (upload_check_timer) {
        //   clearTimeout(upload_check_timer);
        // }
        upload_check_timer = setTimeout(() => {
          dispatch(
            upload_look_checker({
              called_count: upload_look_checker_state.called_count + 1,
              called_id: { ...upload_look_checker_state.called_id },
            })
          );
        }, 3000);
      }
    } catch (e) {
      const error = e as AxiosError;
      toast.error(error.message || "Something went wrong with uploading");
      if (upload_check_timer) {
        clearTimeout(upload_check_timer);
      }
      ServiceDataControl.get_last_viewed_style(dispatch);
      dispatch(is_clicked(false))
      dispatch(
        upload_look_checker({
          called_count: 0,
          called_id: {
            ID: 0,
            minutesRemaining: 0,
            secondsRemaining: 0,
          },
        })
      );
      dispatch(change_loading(false));
    }
  };

export const get_all_looks =
  () =>
  // (setIsRequestProcessing: (value: boolean) => void | null) =>
  async (dispatch: AppDispatch) => {
    try {
      // setIsRequestProcessing(true);
      // dispatch(change_loading(false));
      const response = await fetchingDataWithAxiosMiddleware(
        "GET",
        ServiceApi.get_all_looks_url()
      );
      if (response.status) {
        const data = await response.data;
        if (data) {
          // setIsRequestProcessing(false);
          dispatch(set_all_looks(data));
        }
      }
      // dispatch(change_loading(false));
    } catch (e) {
      const error = e as AxiosError;
      dispatch(change_loading(false));
      dispatch(set_common_reducer_state(common_reducer_initial_state));
      dispatch(set_config_reducer_state(config_reducer_initial_state));
      dispatch(change_extra_modal_state());
      dispatch(change_modal("Inspiration"));
      toast.error(
        error.message || "Something went wrong with looks, please try again"
      );
    }
  };
