export const SET_OLD_STILL_PHOTO_URL: string = "SET OLD STILL PHOTO URL";
export const SET_STILL_PHOTO_ENDPOINT: string = "SET STILL PHOTO ENDPOINT";
export const SET_NEW_STILL_PHOTO_URL: string = "SET NEW STILL PHOTO URL";



export const set_old_still_photo_url = (payload: string) => {
  return {
    type: SET_OLD_STILL_PHOTO_URL,
    payload,
  };
};

export const set_still_photo_endpoint = (payload: string) => {
  return {
    type: SET_STILL_PHOTO_ENDPOINT,
    payload,
  };
};

export const set_new_still_photo_url = (payload: string) => {
  return {
    type: SET_NEW_STILL_PHOTO_URL,
    payload,
  };
};
