import React, {FC, useCallback} from 'react'
import stillPhotoStyle from './stillPhoto.module.scss'
import StillMarker from "../../Common/Markers/marker";
import {
    change_showed_sphere,
    clear_loading_time,
    set_new_spheres_status,
    set_old_spheres_status
} from "../../Redux/Actions";
import ViewerLoading from "../PhotoSphereViewer/MiniLoading/photosphereLoading";
import toast from "react-hot-toast";
import share_vector from "../../Assets/Vectors/share.svg";
import view_new from "../../Assets/Images/view_new.svg";
import view_old from "../../Assets/Images/view_old.svg";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../Redux/store";
import {typedSelector} from "../../Hooks/useTypedSelector";
import {ServicePagesControl} from "../../Services";
import UseImageProportion from "./useImageProportion";

const StillPhoto: FC = () => {
    const container_min_height = UseImageProportion()
    const dispatch = useDispatch<AppDispatch>();

    const common = typedSelector((state) => state.common);
    const config = typedSelector((state) => state.config);
    const {result} = common;
    const {
        is_new_sphere,
        is_still_photo,
        sphere_statuses,
        look_uploaded
    } = config;

    const copied_url: () => string = useCallback(() => {
        return ServicePagesControl.share_url_generator(
            common.look.url,
            is_still_photo ? result.new_still_photo_url : result.new_sphere_url,
            is_still_photo,
            look_uploaded,
            common.result_checker_state.called_id.ID || 0
        )
    }, [common.result_checker_state.called_id.ID]);

    return <div className={stillPhotoStyle.still_photos}>
        <div
            style={!sphere_statuses.new_sphere ? {minHeight: container_min_height} : {}}
            className={stillPhotoStyle.new_still_photo_container}
        >
            {result.new_still_photo_url ?
                <StillMarker/>
                : <></>
            }
            {result.new_still_photo_url ? (
                <img
                    src={result.new_still_photo_url}
                    alt="New result"
                    onLoad={() => {
                        dispatch(set_new_spheres_status(true));
                        dispatch(clear_loading_time());
                    }}
                />
            ) : (
                <></>
            )}
            {!sphere_statuses.new_sphere && (
                <div className={stillPhotoStyle.loading_container_still_photo}>
                    <ViewerLoading sphere_type="new"/>
                </div>
            )}
            <div
                className={
                    is_new_sphere
                        ? `${stillPhotoStyle.old_still_photo_wrapper} ${stillPhotoStyle.hide}`
                        : `${stillPhotoStyle.old_still_photo_wrapper} ${stillPhotoStyle.show}`
                }
            >
                <img
                    src={result.old_still_photo_url}
                    alt="Old result"
                    onLoad={() => dispatch(set_old_spheres_status(true))}
                />
                {/*{!sphere_statuses.old_sphere && <ViewerLoading sphere_type="old" />}*/}
            </div>

            <div className={stillPhotoStyle.still_photo_navbar}>
                {sphere_statuses.new_sphere ? (
                    is_new_sphere ? (
                        <img
                            className={stillPhotoStyle.share_button}
                            onClick={() => {
                                if (navigator?.share) {
                                    navigator
                                        .share({
                                            title: "Check out this look in my space!",
                                            url: copied_url(),
                                        })
                                        .then(() => console.log("Success"))
                                        .catch(() => console.log("Canceled"));
                                } else {
                                    console.warn(
                                        "Your browser doesn't have native share"
                                    );
                                }
                                if (
                                    navigator?.clipboard &&
                                    navigator?.clipboard?.writeText
                                ) {
                                    navigator.clipboard.writeText(copied_url()).then(() => {
                                        toast("Link copied to clipboard");
                                    });
                                } else {
                                    console.warn("Please update your browser!!");
                                }
                            }}
                            src={share_vector}
                            alt="share"
                        />
                    ) : (
                        <></>
                    )
                ) : (
                    ""
                )}
                <div
                    className={stillPhotoStyle.new_old_clickable_area}
                    onClick={() => {
                        dispatch(change_showed_sphere());
                    }}
                >
                    {sphere_statuses.new_sphere &&
                    (is_new_sphere ? (
                        <img
                            className={stillPhotoStyle.view_old}
                            src={view_new}
                            alt="view old sphere"
                        />
                    ) : (
                        <img
                            className={stillPhotoStyle.view_new}
                            src={view_old}
                            alt="view new sphere"
                        />
                    ))}
                </div>
            </div>
        </div>
    </div>
}

export default StillPhoto