import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import axios, { CancelTokenSource } from "axios";
import { AppDispatch } from "../../../Redux/store";
import resultStyle from "./sphereResult.module.scss";
import { useAsyncActions } from "../../../Hooks/useActions";
import WallsAndTiles from "../../WallsAndTiles/wallsAndTiles";
import { typedSelector } from "../../../Hooks/useTypedSelector";
import PhotoSphereViewer from "../../PhotoSphereViewer/photoSphereViewer";
import { change_showed_sphere, set_all_spheres } from "../../../Redux/Actions";
import StillPhoto from "../../StillPhoto/stillPhoto";

const SphereResult: FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const cancelToken: CancelTokenSource = axios.CancelToken.source();
  const { get_sphere_name, get_all_spheres } = useAsyncActions();
  const spheres = typedSelector((state) => state.common.spheres);
  const result_checker_state = typedSelector((state) => state.common.result_checker_state);
  const is_still_photo = typedSelector((state) => state.config.is_still_photo);
  const is_reminder = typedSelector((state) => state.config.is_reminder);

  useEffect(() => {
    const app_state = JSON.parse(String(sessionStorage.getItem("common")));
    if (!spheres.length && !app_state?.spheres.length) {
      get_all_spheres();
    }
    if (!spheres.length && app_state?.spheres.length) {
      dispatch(set_all_spheres(app_state?.spheres));
    }
    dispatch(change_showed_sphere(true));
  }, []);

  useEffect(() => {
    if (result_checker_state.called_id.ID) {
      get_sphere_name({
        result_checker_state: result_checker_state,
        process_type: !is_still_photo ? "hardcodedsphere" : "stillphoto",
        cancelToken: cancelToken.token,
        is_reminder: is_reminder
      });
    }
    return () => {
      cancelToken.cancel();
    };
  }, [
    result_checker_state
  ]);


  return (
    <>
      <div className={resultStyle.sphere_block}>
        {
          is_still_photo ? <StillPhoto /> : <PhotoSphereViewer />
        }
      </div>
      <WallsAndTiles />
    </>
  );
};

export default SphereResult;
