import { combineReducers } from "redux";
import { configReducer } from "./configReducer";
import { commonReducer } from "./commonReducer";
import { assetsReducer } from "./assetsReducer";

export const rootReducer = combineReducers({
  common: commonReducer,
  config: configReducer,
  assets: assetsReducer,
});
