import {useEffect, useState} from "react";
import {typedSelector} from "./useTypedSelector";


const default_circle_length: number = 9

export const handler_loading_time = (
    min: number,
    sec: number,
    viewer_time: number
): { min: number; sec: number; circle_length: number } => {
    const second = sec + viewer_time;
    const circle_length = min * 60 + second
    return {
        min: second > 60 ? min + 1 : min,
        sec: second >= 60 ? second - 60 : second,
        circle_length: circle_length
    };
};

const useLoadingTimer = (sphere_type: string) => {
    const is_still_photo = typedSelector(state => state.config.is_still_photo)
    const loading_time = typedSelector(state => state.common.loading_time)


    const [loading_and_time, setLoadingAndTime] = useState<{
        step: number;
        count: number;
        min: number;
        sec: number;
        circle_length: number;
    }>({
        step: 0,
        count: 0,
        min: 0,
        sec: 0,
        circle_length: default_circle_length
    })

    const viewer_photo_reading_time: number = is_still_photo ? 1 : 2;

    let interval: NodeJS.Timeout | null = null;

    useEffect(() => {
        if (sphere_type === "old") return
        if (
            loading_time.minutesRemaining >= 0 &&
            loading_time.secondsRemaining > 0
        ) {
            clearInterval(interval!);
            setLoadingAndTime((prev) => {
                return {
                    ...prev,
                    ...handler_loading_time(
                        loading_time.minutesRemaining,
                        loading_time.secondsRemaining,
                        viewer_photo_reading_time
                    )
                }
            });
        }
    }, [loading_time.id]);


    useEffect(() => {
        if (sphere_type === "old") return
        if (!loading_and_time.min && !loading_and_time.sec && !loading_and_time.count) {
            clearInterval(interval!);
            if(!loading_and_time.step){
                setLoadingAndTime((prev) => {
                    return {
                        ...prev,
                        step: 1
                    }
                })
            }
            return
        } else {
            if (loading_and_time.step < loading_and_time.circle_length) {
                clearInterval(interval!);
                interval = setInterval(() => {
                    setLoadingAndTime((prev) => {
                        if (prev.sec === 0 && prev.min > 0) {
                            return {
                                ...prev,
                                min: prev.min - 1,
                                sec: 59,
                                step: prev.step + 1
                            };
                        }
                        if (prev.sec) {
                            return {
                                ...prev,
                                sec: prev.sec - 1,
                                step: prev.step + 1
                            };
                        }
                        if (prev.sec === 0 && prev.min === 0) {
                            return {
                                ...prev,
                                step: prev.step + 1,
                                circle_length: default_circle_length
                            };
                        }
                        return prev;
                    });
                }, 1000);
            }

            if (loading_and_time.step === loading_and_time.circle_length) {
                clearInterval(interval!);

                interval = setInterval(() => {
                    setLoadingAndTime((prev) => {
                        return {
                            ...prev,
                            step: 0,
                            count: prev.count + 1
                        };
                    });
                }, 1000);
            }
        }

        return () => {
            clearInterval(interval!);
        };
    }, [loading_and_time.step, loading_and_time.circle_length]);


    return {
        loading_time: {
            min: loading_and_time.min,
            sec: loading_and_time.sec,
            circle_length: loading_and_time.circle_length
        },
        loading: {
            step: loading_and_time.step,
            count: loading_and_time.count,
        }
    };

};

export default useLoadingTimer;