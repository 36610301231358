import resultStyle from "../Sphere/SphereResult/sphereResult.module.scss";
import { change_fullscreen_state, change_material_type, change_showed_sphere } from "../../Redux/Actions";
import { AppDispatch } from "../../Redux/store";
import { LookDataI } from "../../Types";
import view_new from "../../Assets/Vectors/open_eye.svg";
import view_old from "../../Assets/Vectors/closed_eye.svg";
import full_screen_off from "../../Assets/Vectors/full_screen_off.svg";
import full_screen_on from "../../Assets/Vectors/full_screen_on.svg";
import useTwoFingersStyle from "./MiniLoading/photosphereLoading.module.scss";
import Logo from "../../Assets/Images/logo.svg";
import { isIOS } from "react-device-detect";

// export const copyToClipboard = (
//   text_to_copy: string,
//   setLinkInClipboard: Dispatch<SetStateAction<boolean>>
// ) => {
//   // const title: string = "Check out this look in my space!",
//   //   description: string = "DoSpace | See any look in your space";
//   if (navigator.vendor.includes("Apple")) {
//     navigator
//       .share({
//         title: "Check out this look in my space!",
//         url: text_to_copy,
//       })
//       .then(() => "Link copied in the clipboard");
//   }
//   if (navigator.share) {
//     navigator
//       .share({
//         title: "Check out this look in my space!",
//         url: text_to_copy,
//       })
//       .then(() => "Link copied in the clipboard");
//   }
//   navigator
//     .share({
//       title: "Check out this look in my space!",
//       url: text_to_copy,
//     })
//     .then(() => "Link copied in the clipboard");
//
//   navigator.clipboard.writeText(text_to_copy).then(() => {
//     setTimeout(() => {
//       setLinkInClipboard(false);
//     }, 1500);
//   });
// };

export const write_sphere_type = () => {
    return (
        <div className={resultStyle.random_sphere}>
            <span>DEMO ROOM</span>
        </div>
    );
};

export const showed_sphere_icon = (
    is_new_sphere: boolean,
    dispatch: AppDispatch,
) => {
    if (is_new_sphere) {
        return (
            <>
                <div
                    onClick={() => dispatch(change_showed_sphere())}
                    className={resultStyle.new_old_clickable_area}
                />
                <img
                    onClick={(e) => {
                        e.stopPropagation();
                        dispatch(change_showed_sphere());
                    }}
                    className={resultStyle.view_old}
                    src={view_new}
                    alt="view old sphere"
                />
            </>
        );
    }

    return (
        <>
            <div
                onClick={() => dispatch(change_showed_sphere())}
                className={resultStyle.new_old_clickable_area}
            />
            <img
                onClick={(e) => {
                    e.stopPropagation();
                    dispatch(change_showed_sphere());
                }}
                className={resultStyle.view_new}
                src={view_old}
                alt="view new sphere"
            />
        </>
    );
};

export const fullscreen_icon = (
    is_fullscreen_enabled: boolean,
    dispatch: AppDispatch
) => {
    if (is_fullscreen_enabled) {
        return (
            <>
                <div
                    onClick={() => {

                        dispatch(change_fullscreen_state())
                    }}
                    className={resultStyle.full_screen_clickable_area}
                />
                <img
                    onClick={(e) => {
                        e.stopPropagation();
                        dispatch(change_fullscreen_state())
                    }}
                    className={resultStyle.full_screen_off}
                    src={full_screen_off}
                    alt="full screen off"
                />
            </>
        );
    }
    return (
        <>
            <div
                onClick={() => {
                    dispatch(change_fullscreen_state())
                }}
                className={resultStyle.full_screen_clickable_area}
            />
            <img
                onClick={() => {
                    dispatch(change_fullscreen_state())
                }}
                className={resultStyle.full_screen_on}
                src={full_screen_on}
                alt="full screen on"
            />
        </>
    );
};

export const green_dots_and_tag_markers_functional = (
    dispatch: AppDispatch,
    look: LookDataI,
    material_type: string
) => {

    const container_wall: HTMLElement | null = document.getElementById(
        "container-wall"
    );
    const container_tile: HTMLElement | null = document.getElementById(
        "container-tile"
    );

    let interval: NodeJS.Timeout | null = null;
    const time_out: number = 300
    if (container_wall) {
        container_wall?.addEventListener("click", () => {
            if (material_type === "tile") {
                interval = setTimeout(() => {
                    dispatch(change_material_type("wall"))
                }, time_out / 2)
            }
            if (material_type === "wall") {
                interval = setTimeout(() => {
                    window.open(look?.wallColor.urlOnVendorPage, "_blank")
                }, time_out)
            }
        })
        container_wall?.addEventListener("mouseleave", () => {
            if (interval) {
                clearTimeout(interval!)
            }
        })
        container_wall?.addEventListener(isIOS ? "touchend" : "touchstart", () => {
            if (material_type === "tile") {
                interval = setTimeout(() => {
                    dispatch(change_material_type("wall"))
                }, time_out / 2)
            }
            if (material_type === "wall") {
                interval = setTimeout(() => {
                    window.open(look?.wallColor.urlOnVendorPage, "_blank")
                }, time_out)
            }
        })
        container_wall?.addEventListener("touchmove", () => {
            if (interval) {
                clearTimeout(interval!)
            }
        })
    }
    if (container_tile) {
        container_tile?.addEventListener("click", () => {
            if (material_type === "wall") {
                interval = setTimeout(() => {
                    dispatch(change_material_type("tile"))
                }, time_out / 2)
            }
            if (material_type === "tile") {
                interval = setTimeout(() => {
                    window.open(look?.floorTile.urlOnVendorPage, "_blank")
                }, time_out)
            }
        })
        container_tile?.addEventListener("mouseleave", () => {
            if (interval) {
                clearTimeout(interval!)
            }
        })

        container_tile?.addEventListener(isIOS ? "touchend" : "touchstart", () => {
            if (material_type === "wall") {
                interval = setTimeout(() => {
                    dispatch(change_material_type("tile"))
                }, time_out / 2)
            }
            if (material_type === "tile") {
                interval = setTimeout(() => {
                    window.open(look?.floorTile.urlOnVendorPage, "_blank")
                }, time_out)
            }
        })
        container_tile?.addEventListener("touchmove", () => {
            if (interval) {
                clearTimeout(interval!)
            }
        })
    }
};

// const viewer_url: string = handler_shared_url({
//   is_still_photo,
//   is_new_sphere,
//   new_still_photo_url: result.new_still_photo_url,
//   old_still_photo_url: result.old_still_photo_url,
//   new_sphere_url: ServiceApi.get_new_share_url(`${result.new_sphere_url.slice(
//     result.new_sphere_url.length - result.new_sphere_url.split("").reverse().join("").indexOf("/"),
//     result.new_sphere_url.length - result.new_sphere_url.split("").reverse().join("").indexOf(".") - 1)}`) || result.new_sphere_url,
//   old_sphere_url: ServiceApi.get_new_share_url(result.sphere_endpoint)
// });

export const use_two_fingers = () => {
    // if (
    //   document.querySelectorAll(
    //     `.${useTwoFingersStyle.use_two_fingers_container}`
    //   ).length !== 2
    // ) {
    //   return;
    // }
    let use_two_fingers_div_list: NodeListOf<HTMLDivElement> =
        window.document.querySelectorAll(".psv-overlay");
    use_two_fingers_div_list.forEach((use_two_fingers) => {
        if (use_two_fingers !== null) {
            const psv_overlay_image_div_list: NodeListOf<HTMLDivElement> =
                    document.querySelectorAll(".psv-overlay-image"),
                psv_overlay_title_div_list: NodeListOf<HTMLDivElement> =
                    document.querySelectorAll(".psv-overlay-title"),
                psv_overlay_text_div_list: NodeListOf<HTMLDivElement> =
                    document.querySelectorAll(".psv-overlay-text");
            psv_overlay_image_div_list.forEach((psv_overlay_image) => {
                if (psv_overlay_image !== null) {
                    psv_overlay_image.style.display = "none";
                }
            });
            psv_overlay_title_div_list.forEach((psv_overlay_title) => {
                if (psv_overlay_title !== null) {
                    psv_overlay_title.style.display = "none";
                }
            });
            psv_overlay_text_div_list.forEach((psv_overlay_text) => {
                if (psv_overlay_text !== null) {
                    psv_overlay_text.style.display = "none";
                }
            });
            use_two_fingers.style.opacity = "1";
            const container: HTMLDivElement = document.createElement("div");
            container.className = useTwoFingersStyle.use_two_fingers_container;
            const logo: HTMLImageElement = document.createElement("img");
            logo.className = useTwoFingersStyle.logo;
            logo.src = Logo;
            container.appendChild(logo);
            use_two_fingers.append(container);
        }
    });
    // let : HTMLDivElement | null =
    //   window.document.querySelector(".psv-overlay");
};
